import React from 'react'

import EmptyState from '../../../../assets/dashboard/recentSearches/emptyState.svg?react'

export const Error404 = () => {
  return (
    <div className="absolute inset-0 flex w-full h-full justify-center items-center">
      <div
        id="emptyState"
        className="flex flex-col justify-center items-center p-8"
      >
        <EmptyState className="" />
        <p className="text-base font-bold tracking-wide my-6 text-center">
          Error 404: We couldn't find the requested page.
        </p>
      </div>
    </div>
  )
}
