import { Trash } from 'iconsax-react'
import React from 'react'
import { toast } from 'sonner'

import {
  AlertDialogHeader,
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
  AlertDialogAction,
  AlertDialogFooter,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import {
  useDeletePostMutation,
  useDeleteWebSearchPostMutation,
} from '@/store/api/Subjects/Subjects.query'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface deletePostTypes {
  postId: string
  subjectId: string
  id: string
  type?: string
}

export const DeletePostPopUp = ({
  subjectId,
  postId,
  id,
  type,
}: deletePostTypes) => {
  const isWebSearchPost = type === 'WebSearch'
  const [deletePost, { isLoading }] = useDeletePostMutation()
  const [deleteWebSearchPost, { isLoading: isLoadingDeleteWebSearchPost }] =
    useDeleteWebSearchPostMutation()

  const handleDeletePost = async () => {
    try {
      await deletePost({
        id: id,
        subjectId: subjectId,
        postId: postId,
      }).unwrap()
      toast.success('Post deleted')
    } catch (e) {
      catchWithSentry(e)
      toast.error('Error deleting Post')
    }
  }
  const handleDeleteWebSearchPost = async () => {
    try {
      await deleteWebSearchPost({
        subjectId: subjectId,
        postId: postId,
      }).unwrap()
      toast.success('Web Search Post deleted')
    } catch (e) {
      catchWithSentry(e)
      toast.error('Error deleting Web Search Post')
    }
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger>
        <Button
          className="w-10 h-10 bg-gray-200 rounded-[100px] justify-center items-center gap-1 mr-2"
          size={'icon'}
        >
          <Trash size="24" color={weCheckPalette.icon.disableIcon} />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="flex flex-col md:w-6/12 max-md:w-11/12 gap-8">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-neutral-800 text-[24px] font-medium tracking-wide max-sm:text-[30px] max-sm:text-left">
            Confirm Delete
          </AlertDialogTitle>
          <AlertDialogDescription className="text-neutral-800 text-sm font-medium tracking-tight max-sm:text-left">
            {isWebSearchPost
              ? 'Are you sure you want to delete this Web Search post?'
              : 'Are you sure you want to delete this post?'}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter className="flex flex-row items-center gap-2 justify-center">
          <AlertDialogCancel className="w-[101px] h-11 px-5 py-3 bg-zinc-100 rounded max-sm:w-full">
            <p className="text-neutral-800 text-base font-medium tracking-widest">
              Cancel
            </p>
          </AlertDialogCancel>
          <AlertDialogAction
            className="w-[101px] h-11 px-5 py-3 bg-neutral-800 rounded max-sm:mt-2 max-sm:w-full"
            onClick={
              isWebSearchPost ? handleDeleteWebSearchPost : handleDeletePost
            }
            disabled={isLoading || isLoadingDeleteWebSearchPost}
          >
            <p className="text-neutral-50 text-base font-medium tracking-widest ">
              Confirm
            </p>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
