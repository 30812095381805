import { Eye } from 'iconsax-react'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import type { ReportItem } from '@/store/api/Admin/Admin.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { ReportPopUpCard } from './ReportPopUpCard'

export const ReportPopUp = ({ data }: { data: ReportItem }) => {
  const reportDetails = [
    { title: 'Name', text: data.inputs.name || '--' },
    { title: 'Nickname', text: data.inputs.nickname || '--' },
    { title: 'Email', text: data.inputs.email || '--' },
    { title: 'Phone', text: data.inputs.phone || '--' },
    {
      title: 'Age or Date of Birth',
      text: data.inputs.ageOrDateOfBirth || '--',
    },
    { title: 'State', text: data.inputs.state || '--' },
    { title: 'Country', text: data.inputs.country || '--' },
    { title: 'City', text: data.inputs.city || '--' },
    { title: 'College', text: data.inputs.college || '--' },
    { title: 'High School', text: data.inputs.highSchool || '--' },
    { title: 'Facebook', text: data.inputs.facebookUrl || '--' },
    { title: 'Instagram', text: data.inputs.instagramUrl || '--' },
    { title: 'Twitter', text: data.inputs.twitterUrl || '--' },
    { title: 'Linkedin', text: data.inputs.linkedInUrl || '--' },
    { title: 'Tik Tok', text: data.inputs.tikTokUrl || '--' },
    {
      title: 'Language',
      text: data.pdfLanguage === 'en' ? 'English' : 'Spanish',
    },
    {
      title: 'Topics',
      text:
        data.inputs.topics.length > 0 ? data.inputs.topics.join(' - ') : '--',
    },
    { title: 'Website', text: data.inputs.website || '--' },
    { title: 'Industry', text: data.inputs.industry || '--' },
  ]

  const pdfPreferences = [
    { title: 'Core Findings', text: data.pdfCoreFindings ? 'Yes' : 'No' },
    {
      title: 'Social Media Accounts',
      text: data.pdfSocialMediaAccounts ? 'Yes' : 'No',
    },
    {
      title: 'Sentiment Analysis',
      text: data.pdfSentimentAnalysis ? 'Yes' : 'No',
    },
    {
      title: 'Flagged Behaviors',
      text: data.pdfFlaggedBehaviors ? 'Yes' : 'No',
    },
    { title: 'Positive Posts', text: data.pdfPositivePosts ? 'Yes' : 'No' },
    { title: 'Negative Posts', text: data.pdfNegativePosts ? 'Yes' : 'No' },
    { title: 'Neutral Posts', text: data.pdfNeutralPosts ? 'Yes' : 'No' },
    { title: 'Web Search Posts', text: data.pdfWebSearchPosts ? 'Yes' : 'No' },
    { title: 'Focused Topics', text: data.pdfFocusedTopics ? 'Yes' : 'No' },
  ]

  return (
    <Dialog>
      <DialogTrigger>
        <Button
          className="w-10 h-10 bg-gray-200 rounded-[100px] justify-center items-center gap-1 mr-2"
          size="icon"
        >
          <Eye
            width={22}
            height={22}
            variant="Outline"
            color={weCheckPalette.icon.disableIcon}
          />
        </Button>
      </DialogTrigger>
      <DialogContent
        className="flex flex-col justify-start gap-10"
        maxWidth="max-w-5xl"
      >
        <div className="flex flex-col gap-6">
          <DialogTitle>Report Inputs</DialogTitle>
          <div className="flex gap-3 flex-wrap justify-between">
            {reportDetails.map((detail, index) => (
              <ReportPopUpCard
                key={index}
                title={detail.title}
                text={detail.text}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <DialogTitle>PDF Preferences</DialogTitle>
          <div className="flex gap-3 flex-wrap justify-between">
            {pdfPreferences.map((preference, index) => (
              <ReportPopUpCard
                key={index}
                title={preference.title}
                text={preference.text}
              />
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
