import React from 'react'

interface BalanceCardTypes {
  name: string
  credits?: number
  icon: any
}

export const BalanceCard = ({ name, credits, icon }: BalanceCardTypes) => {
  return (
    <div className="flex h-5 items-center w-60">
      <p className="text-md font-medium text-gray-500 w-full">{name}</p>
      <div className="flex gap-1.5 items-center">
        <p className="text-md font-medium tracking-tight">{credits}</p>
        {icon}
      </div>
    </div>
  )
}
