import { Eye } from 'iconsax-react'
import { Link } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

interface PersonalInfoPopUpInterface {
  pinterestUrl: string
  redditUrl: string
  tiktokUrl: string
  linkedInUrl: string
  instagramUrl: string
  facebookUrl: string
  twitterUrl: string
  profilePicUrl: string
}

export const PersonalInfoPopUp = ({
  pinterestUrl,
  redditUrl,
  tiktokUrl,
  linkedInUrl,
  instagramUrl,
  facebookUrl,
  twitterUrl,
  profilePicUrl,
}: PersonalInfoPopUpInterface) => {
  return (
    <Dialog>
      <DialogTrigger>
        <Button
          className="w-10 h-10 bg-gray-200 rounded-[100px] justify-center items-center gap-1 mr-2"
          size={'icon'}
        >
          <Eye width={22} height={22} variant="Outline" color="#111111" />
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[60%]">
        <DialogHeader>
          <DialogTitle>Pinterest Url</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <Link to={pinterestUrl} className="text-wrap">
            {pinterestUrl || '-'}
          </Link>
        </DialogDescription>
        <DialogHeader>
          <DialogTitle>Reddit Url</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <Link to={redditUrl}>{redditUrl || '-'}</Link>
        </DialogDescription>
        <DialogHeader>
          <DialogTitle>Tiktok Url</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <Link to={tiktokUrl}>{tiktokUrl || '-'}</Link>
        </DialogDescription>
        <DialogHeader>
          <DialogTitle>LinkedIn Url</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <Link to={linkedInUrl}>{linkedInUrl || '-'}</Link>
        </DialogDescription>
        <DialogHeader>
          <DialogTitle>Instagram Url</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <Link to={instagramUrl}>{instagramUrl || '-'}</Link>
        </DialogDescription>

        <DialogHeader>
          <DialogTitle>Facebook Url</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <Link to={facebookUrl}>{facebookUrl || '-'}</Link>
        </DialogDescription>
        <DialogHeader>
          <DialogTitle>Twitter Url</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <Link to={twitterUrl}>{twitterUrl || '-'}</Link>
        </DialogDescription>
        <DialogHeader>
          <DialogTitle>Profile Pic Url</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <Link to={profilePicUrl}>{profilePicUrl || '-'}</Link>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  )
}
