import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { BackHeader } from '@/components/BackHeader/BackHeader'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { AppRoutes } from '@/constants/appRoutes'
import { useModifyPersonDataMutation } from '@/store/api/Subjects/Subjects.query'
import { filterEmptyFields } from '@/utils/filterEmptyFields'
import {
  modifyPersonDataSchema,
  type modifyPersonDataSchemaType,
} from '@/utils/schemas'

export const ModifyPersonalInfo = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [modifyPersonData, { isLoading }] = useModifyPersonDataMutation()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const currentPersonData: any | undefined = state.currentPersonData
  const form = useForm<modifyPersonDataSchemaType>({
    resolver: zodResolver(modifyPersonDataSchema),
    defaultValues: {
      name: currentPersonData?.name || '',
      nickname: currentPersonData?.nickname || '',
      address: currentPersonData?.address || '',
      city: currentPersonData?.city || '',
      state: currentPersonData?.state || '',
      country: currentPersonData?.country || '',
      email: currentPersonData?.email || '',
      highSchool: currentPersonData?.highSchool || '',
      college: currentPersonData?.college || '',
      company: currentPersonData?.company || '',
      phoneNumber: currentPersonData?.phoneNumber || '',
      ageOrDateOfBirth: currentPersonData?.ageOrDateOfBirth || '',
    },
    mode: 'onChange',
  })

  const { handleSubmit, control } = form

  const handleFileUpload = (event: any) => {
    setSelectedFile(event.target.files[0])
  }

  const handleModifyPersonData = async (data: any) => {
    const formData = new FormData()
    if (selectedFile) {
      formData.append('image', selectedFile)
    }

    const dataObject: any = {
      name: data.name || '',
      nickname: data.nickname || '',
      address: data.address || '',
      city: data.city || '',
      state: data.state || '',
      country: data.country || '',
      email: data.email || '',
      college: data.college || '',
      highSchool: data.highSchool || '',
      company: data.company || '',
      phoneNumber: data.phoneNumber || '',
      ageOrDateOfBirth: data.ageOrDateOfBirth || '',
    }
    const filteredDataObject = filterEmptyFields(dataObject)

    Object.keys(filteredDataObject).forEach((key) => {
      formData.append(key, filteredDataObject[key])
    })

    try {
      await modifyPersonData({ formData, subjectId: state.subjectId }).unwrap()
      toast.success('Person Data modified successfully.')
      navigate(AppRoutes.socialMedia, {
        state: state.subjectId,
      })
    } catch (e) {
      toast.error('Error modifying Person Data.')
      console.error(e)
    }
  }

  const onSubmit = (data: any) => {
    handleModifyPersonData(data)
  }
  return (
    <div>
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row items-center">
            <BackHeader
              backText=""
              onPressBack={() =>
                navigate(AppRoutes.socialMedia, {
                  state: state.subjectId,
                })
              }
            />
          </div>
          <Card className="mt-8">
            <div className="p-6">
              <div className="flex justify-between mb-1 lg:flex-row flex-col-reverse gap-4 mb-6">
                <p className="text-neutral-800 text-xl font-medium">
                  Modify Person Data
                </p>
              </div>
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-3">
                  <FormField
                    control={control}
                    name="name"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Name</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Name" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="nickname"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Nickname</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="nickname" />
                        </FormControl>
                        <FormMessage data-name="nickname" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="address"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6">
                        <FormLabel>Address</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="address" />
                        </FormControl>
                        <FormMessage data-name="address" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="city"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6">
                        <FormLabel>City</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="city" />
                        </FormControl>
                        <FormMessage data-name="city" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="state"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6 ">
                        <FormLabel>State</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="state" />
                        </FormControl>
                        <FormMessage data-name="state" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="country"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Country</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="country" />
                        </FormControl>
                        <FormMessage data-name="country" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="email" />
                        </FormControl>
                        <FormMessage data-name="email" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="highSchool"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>HighSchool</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="highSchool" />
                        </FormControl>
                        <FormMessage data-name="highSchool" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="college"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>College</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="college" />
                        </FormControl>
                        <FormMessage data-name="college" />
                      </FormItem>
                    )}
                  />{' '}
                  <FormField
                    control={control}
                    name="company"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Company</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="company" />
                        </FormControl>
                        <FormMessage data-name="company" />
                      </FormItem>
                    )}
                  />{' '}
                  <FormField
                    control={control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Phone Number</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Phone Number" />
                        </FormControl>
                        <FormMessage data-name="phoneNumber" />
                      </FormItem>
                    )}
                  />{' '}
                  <FormField
                    control={control}
                    name="ageOrDateOfBirth"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Age Or Date Of Birth</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="ageOrDateOfBirth" />
                        </FormControl>
                        <FormMessage data-name="ageOrDateOfBirth" />
                      </FormItem>
                    )}
                  />
                  <FormLabel>Profile Pic</FormLabel>
                  <Input
                    className="py-2.5 font-medium tracking-widest"
                    accept="image"
                    type="file"
                    onChange={handleFileUpload}
                  />
                </div>
                <div className="flex gap-6">
                  <Button
                    isLoading={isLoading}
                    type="submit"
                    className="h-12 px-5 py-3 bg-neutral-800 rounded-lg"
                  >
                    <p className="text-neutral-50 text-base font-medium tracking-wide">
                      Save
                    </p>
                  </Button>
                  <Button
                    className="w-24 h-12 px-5 py-3 bg-zinc-100 rounded-lg"
                    onClick={() =>
                      navigate(AppRoutes.socialMedia, {
                        state: state.subjectId,
                      })
                    }
                  >
                    <p className="text-neutral-800 text-base font-medium tracking-wide">
                      Cancel
                    </p>
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </form>
      </Form>
    </div>
  )
}
