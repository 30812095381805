import { useEffect } from 'react'

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { useAppSelector } from '@/hooks/redux'
import { useRedirectBasedOnPermissions } from '@/hooks/useRedirectBasedOnPermissions'
import { selectItsDefaultState } from '@/store/api/Users/Users.selector'

import { EnterprisesTable } from './Components/EnterprisesTable'

export const Enterprises = () => {
  const { hasHandleEnterprises } = useRedirectBasedOnPermissions()
  const itsDefaultState = useAppSelector(selectItsDefaultState)

  useEffect(() => {
    if (!itsDefaultState) hasHandleEnterprises()
  }, [itsDefaultState])
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl text-textAccent font-semibold">
          Enterprise Available
        </CardTitle>
      </CardHeader>
      <CardContent>
        <EnterprisesTable />
      </CardContent>
    </Card>
  )
}
