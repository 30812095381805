import type {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Add } from 'iconsax-react'
import { isEmpty } from 'ramda'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { EmptyState } from '@/components/EmptyState/EmptyState'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { AppRoutes } from '@/constants/appRoutes'
import type { getSubjectResponse } from '@/store/api/Subjects/Subjects.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { DeleteSocialMediaPopUp } from './DeleteSocialMediaPopUp'
import { ModifySocialMediaPopUp } from './ModifySocialMediaPopUp'
import { SocialMediaPopUp } from './SocialMediaPopUp'

interface SocialMediaTableTypes {
  data: getSubjectResponse
  subjectId: string
}

export const SocialMediaTable = ({
  data,
  subjectId,
}: SocialMediaTableTypes) => {
  const navigate = useNavigate()
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  )
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})
  const [globalFilter, setGlobalFilter] = React.useState('')

  const columns: ColumnDef<any>[] = [
    {
      accessorKey: 'name',
      header: 'Social Media',
      cell: ({ row }) => (
        <div className="capitalize text-neutral-800 text-base font-normal">
          {row.getValue('name') || <p className="text-red-600">Nameless</p>}
        </div>
      ),
    },
    {
      accessorKey: 'userName',
      header: 'User Name',
      cell: ({ row }) => (
        <div className="capitalize text-neutral-800 text-base font-normal">
          {row.getValue('userName') || <p className="text-red-600">Nameless</p>}
        </div>
      ),
    },
    {
      accessorKey: 'followers',
      header: 'Followers',
      cell: ({ row }) => (
        <div className="text-neutral-800 text-base font-normal">
          {row.getValue('followers') || <p className="text-red-600">-</p>}
        </div>
      ),
    },
    {
      accessorKey: 'following',
      header: 'Following',
      cell: ({ row }) => (
        <div className="text-neutral-800 text-base font-normal">
          {row.getValue('following') || <p className="text-red-600">-</p>}
        </div>
      ),
    },
    {
      accessorKey: 'postsCount',
      header: () => 'Posts',
      cell: ({ row }) => (
        <div className="flex justify-center">
          {row.getValue('postsCount') || <p className="text-red-600">-</p>}
        </div>
      ),
    },
    {
      accessorKey: 'flaggedCount',
      header: () => 'Flagged Posts',
      cell: ({ row }) => (
        <div className="flex justify-center">
          {row.getValue('flaggedCount') || <p className="text-red-600">-</p>}
        </div>
      ),
    },
    {
      accessorKey: 'isPrivate',
      header: () => 'Is Private?',
      cell: ({ row }) => (
        <div className="flex justify-center">
          {row.getValue('isPrivate') ? (
            <p className="text-green-600">Yes</p>
          ) : (
            <p className="text-red-600">No</p>
          )}
        </div>
      ),
    },
    {
      accessorKey: 'Actions',
      header: () => <p className="text-center">Actions</p>,
      cell: ({ row }) => (
        <div className="flex gap-2 justify-center">
          <SocialMediaPopUp
            bio={data.info.socialMedia[row.index].bio}
            profileImgUrl={data.info.socialMedia[row.index].profileImgUrl}
            profileLink={data.info.socialMedia[row.index].profileLink}
          />
          <ModifySocialMediaPopUp
            id={data.info.socialMedia[row.index].id}
            subjectId={subjectId}
            currentProfileImgUrl={
              data.info.socialMedia[row.index].profileImgUrl
            }
            currentBio={data.info.socialMedia[row.index].bio}
            currentFollowers={data.info.socialMedia[row.index].followers}
            currentFollowing={data.info.socialMedia[row.index].following}
            currentPostsCount={data.info.socialMedia[row.index].postsCount}
            currentProfileLink={data.info.socialMedia[row.index].profileLink}
            currentIsPrivate={data.info.socialMedia[row.index].isPrivate}
          />
          <DeleteSocialMediaPopUp
            subjectId={subjectId}
            id={data.info.socialMedia[row.index].id}
          />
          <Button
            className="w-10 h-10 bg-gray-200 rounded-[100px] justify-center items-center gap-1"
            size={'icon'}
            onClick={() =>
              navigate(AppRoutes.newPost, {
                state: {
                  subjectId: subjectId,
                  id: data.info.socialMedia[row.index].id,
                },
              })
            }
          >
            <Add size="32" color={weCheckPalette.icon.disableIcon} />
          </Button>
        </div>
      ),
    },
  ]

  const table = useReactTable({
    data: data?.info.socialMedia ?? [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      globalFilter,
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  if (isEmpty(data)) {
    return (
      <EmptyState text="We will show here the social media when there are any" />
    )
  }

  return (
    <div className="w-full">
      <div className="flex items-center py-4 h-12 mb-2 justify-between">
        <Input
          placeholder="Filter by any element..."
          value={globalFilter}
          onChange={(event) => {
            const value = event.target.value
            setGlobalFilter(value)
          }}
          className="w-96 h-10"
        />
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  className="bg-backgroundHighlight hover:bg-muted/50 "
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="border-r">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="text-center">
                  <EmptyState text="There seem to be results for your search" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}
