import { RTKTags, api } from '@/store/api'

import Env from '../../../env/env'

import type { addCreditsRequest, setCustomPriceRequest } from './Payments.types'

export const paymentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    setCustomPrice: builder.mutation<void, setCustomPriceRequest>({
      query: (request: setCustomPriceRequest) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/payments/enterprise/${request.id}/custom-price`,
        method: 'PATCH',
        body: {
          amount: request.amount,
          product: request.product,
          creditType: request.creditType,
        },
      }),
      invalidatesTags: [RTKTags.enterprises],
    }),
    addCredits: builder.mutation<void, addCreditsRequest>({
      query: (request: addCreditsRequest) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/admin/enterprises/${request.id}/add-credits`,
        method: 'PATCH',
        body: {
          quantity: request.quantity,
          type: request.type,
        },
      }),
      invalidatesTags: [RTKTags.enterprises],
    }),
  }),
})

export const { useSetCustomPriceMutation, useAddCreditsMutation } = paymentsApi
