import { AddSquare } from 'iconsax-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { AppRoutes } from '@/constants/appRoutes'

import { TopicsTable } from './components/TopicsTable'

export const Topics = () => {
  const navigate = useNavigate()

  return (
    <Card>
      <CardHeader className="flex flex-row justify-between">
        <CardTitle className="text-xl text-textAccent font-semibold">
          Topics
        </CardTitle>
        <Button
          onClick={() => navigate(AppRoutes.newTopic)}
          className="h-10 flex gap-2"
          variant={'outline'}
        >
          <p className="text-medium font-semibold">Create new Topic</p>
          <AddSquare size="22" color="#111111" />
        </Button>
      </CardHeader>
      <CardContent>
        <TopicsTable />
      </CardContent>
    </Card>
  )
}
