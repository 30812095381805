import { isEmpty } from 'ramda'
import { useEffect } from 'react'

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { useAppSelector } from '@/hooks/redux'
import { useRedirectBasedOnPermissions } from '@/hooks/useRedirectBasedOnPermissions'
import { useReportsQuery } from '@/store/api/Admin/Admin.query'
import { selectItsDefaultState } from '@/store/api/Users/Users.selector'

import { CompletedReviewTable } from './components/CompletedReviewTable/CompletedReviewTable'
import { ErrorTable } from './components/ErrorTable/ErrorTable'
import { InQueueTable } from './components/InQueueTable/InQueueTable'
import { PendingComplianceSignedTable } from './components/PendingComplianceSignedTable/PendingComplianceSignedTable'
import { PendingReviewReportTable } from './components/PendingReviewTable/PendingReviewReportTable'

export const Reports = () => {
  const { hasHandleReports } = useRedirectBasedOnPermissions()
  const itsDefaultState = useAppSelector(selectItsDefaultState)

  useEffect(() => {
    if (!itsDefaultState) hasHandleReports()
  }, [itsDefaultState])

  const { isLoading, data } = useReportsQuery({ status: 'ErrorPdf' })

  return (
    <div className="flex flex-col gap-10">
      {data && !isEmpty(data) && (
        <Card className="border-red-500 border shadow-red-500">
          <CardHeader>
            <CardTitle className="text-xl text-red-500 font-semibold">
              Error Reports
            </CardTitle>
          </CardHeader>
          <CardContent>
            <ErrorTable data={data} isLoading={isLoading} />
          </CardContent>
        </Card>
      )}

      <Card>
        <CardHeader>
          <CardTitle className="text-xl text-textAccent font-semibold">
            In Queue
          </CardTitle>
        </CardHeader>
        <CardContent>
          <InQueueTable />
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="text-xl text-textAccent font-semibold">
            Pending Compliance Signed
          </CardTitle>
        </CardHeader>
        <CardContent>
          <PendingComplianceSignedTable />
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="text-xl text-textAccent font-semibold">
            Pending Review
          </CardTitle>
        </CardHeader>
        <CardContent>
          <PendingReviewReportTable />
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="text-xl text-textAccent font-semibold">
            Completed Review
          </CardTitle>
        </CardHeader>
        <CardContent>
          <CompletedReviewTable />
        </CardContent>
      </Card>
    </div>
  )
}
