import { zodResolver } from '@hookform/resolvers/zod'
import { Edit } from 'iconsax-react'
import { useForm, useFieldArray } from 'react-hook-form'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/text-area'
import { useUpdateTopicMutation } from '@/store/api/Topics/Topics.query'
import type {
  updateTopicRequest,
  translation,
} from '@/store/api/Topics/Topics.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import { updateTopicSchema } from '@/utils/schemas'
import type { updateTopicSchemaType } from '@/utils/schemas'

interface ModifyTopicPopUpTypes {
  description?: string
  promp?: string
  translations?: translation[]
  id?: string
  name?: string
  enterprise?: string
}

export const ModifyTopicPopUp = ({
  id,
  promp,
  translations = [],
  name,
  enterprise,
}: ModifyTopicPopUpTypes) => {
  const [updateTopic, { isLoading }] = useUpdateTopicMutation()
  const handleUpdateTopic = async (data: updateTopicSchemaType) => {
    try {
      const updatedTranslations = data.translations?.map(
        (translation, index) => {
          const originalTranslation = translations[index]
          return {
            language: translation.language || originalTranslation.language,
            name: translation.name || originalTranslation.name,
            description:
              translation.description || originalTranslation.description,
            keywords:
              translation.keywords && translation.keywords.length > 0
                ? translation.keywords
                : originalTranslation.keywords || [],
          }
        },
      )

      const requestInfo: updateTopicRequest = {
        id,
        name: data.name || name,
        enterprise: data.enterprise || enterprise,
        promp: data.promp || promp,
        translations: updatedTranslations,
      }

      await updateTopic(requestInfo).unwrap()
      toast.success('Topic updated successfully.')
    } catch (e) {
      toast.error('Error updating topic.')
      console.error(e)
    }
  }

  const form = useForm<updateTopicSchemaType>({
    resolver: zodResolver(updateTopicSchema),
    defaultValues: {
      name,
      enterprise,
      promp,
      translations,
    },
    mode: 'onChange',
  })

  const { handleSubmit, control } = form
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'translations',
  })

  const onSubmit = (data: updateTopicSchemaType) => {
    handleUpdateTopic(data)
  }

  return (
    <Dialog>
      <DialogTrigger>
        <Button
          className="w-10 h-10 bg-gray-200 rounded-[100px] justify-center items-center gap-1 mr-2"
          size={'icon'}
        >
          <Edit
            size="24"
            color={weCheckPalette.icon.disableIcon}
            variant="Outline"
          />
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[60%] h-full overflow-y-scroll flex flex-col">
        <DialogHeader>
          <DialogTitle>Modify Topic</DialogTitle>
        </DialogHeader>
        <div>
          <Form {...form}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              name="UpdateTopicForm"
              className="flex flex-col gap-2"
            >
              <FormField
                control={control}
                name="name"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Name*</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Name" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={control}
                name="enterprise"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Enterprise*</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Enterprise" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={control}
                name="promp"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Promp*</FormLabel>
                    <FormControl>
                      <Textarea {...field} placeholder="Promp" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex flex-col gap-4 mt-4">
                <p className="text-neutral-800 text-xl font-medium my-2">
                  Topics Translations
                </p>
                {fields.map((field, index) => (
                  <div key={field.id} className="mb-4">
                    <FormField
                      control={control}
                      name={`translations.${index}.language`}
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormLabel>Language*</FormLabel>
                          <FormControl>
                            <Input {...field} placeholder="Language" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={control}
                      name={`translations.${index}.name`}
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormLabel>Name*</FormLabel>
                          <FormControl>
                            <Input {...field} placeholder="Name" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={control}
                      name={`translations.${index}.description`}
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormLabel>Description</FormLabel>
                          <FormControl>
                            <Textarea {...field} placeholder="Description" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={control}
                      name={`translations.${index}.keywords`}
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormLabel>Keywords</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              placeholder="Keywords (comma-separated)"
                              onChange={(e) =>
                                field.onChange(
                                  e.target.value
                                    .split(',')
                                    .map((keyword) => keyword.trim()),
                                )
                              }
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <Button
                      type="button"
                      onClick={() => remove(index)}
                      className="h-[40px] mt-3 px-5 py-3 rounded-lg"
                      variant="destructive"
                    >
                      Remove Translation
                    </Button>
                  </div>
                ))}
                <Button
                  type="button"
                  onClick={() =>
                    append({
                      language: '',
                      name: '',
                      description: '',
                      keywords: [],
                    })
                  }
                  className="h-[46px] px-5 py-3 bg-green-700 rounded-lg"
                  variant="default"
                >
                  Add Translation to modify
                </Button>
              </div>

              <Button
                isLoading={isLoading}
                type="submit"
                className="h-[46px] px-5 py-3 bg-neutral-800 rounded-lg"
              >
                <p>Update Topic</p>
              </Button>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
