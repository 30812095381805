import { zodResolver } from '@hookform/resolvers/zod'
import { AddSquare, Edit } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { EmptyState } from '@/components/EmptyState/EmptyState'
import { Loader } from '@/components/Loader/Loader'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { AppRoutes } from '@/constants/appRoutes'
import {
  useLazyGetSubjectPostsQuery,
  useLazyGetSubjectQuery,
} from '@/store/api/Subjects/Subjects.query'
import type { socialMediaSchemaType } from '@/utils/schemas'
import { socialMediaSchema } from '@/utils/schemas'

import { DisplayPersonalInfo } from './components/DisplayPersonalInfo'
import { DisplaySummary } from './components/DisplaySummary'
import { PostsTable } from './components/PostsTable'
import { SocialMediaTable } from './components/SocialMediaTable'

export const Subject = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const [subjectId, setSubjectId] = useState('')

  const [getSubjectData, { data, isLoading }] = useLazyGetSubjectQuery()
  const [
    getSubjectPostsData,
    {
      data: postsData,
      isLoading: isLoadingPostsData,
      isSuccess: isSuccessPostsData,
      isError: isErrorPostsData,
    },
  ] = useLazyGetSubjectPostsQuery()

  useEffect(() => {
    if (state) {
      getSubjectData({ subjectId: state })
      getSubjectPostsData({
        subjectId: state,
      })
      setSubjectId(state)
    }
  }, [])

  const form = useForm<socialMediaSchemaType>({
    resolver: zodResolver(socialMediaSchema),
    defaultValues: {
      subjectId: '',
    },
  })

  const { handleSubmit, control } = form

  const submit = (formData: any) => {
    getSubjectData({ subjectId: formData.subjectId })
    getSubjectPostsData({
      subjectId: formData.subjectId,
    })
    setSubjectId(formData.subjectId)
    form.reset()
  }
  return (
    <div className="flex flex-col gap-10">
      <Card className="p-4 w-full flex flex justify-between">
        <div className="flex flex-col gap-3">
          <CardTitle className="text-xl text-textAccent font-semibold">
            Search
          </CardTitle>
          <Form {...form}>
            <form onSubmit={handleSubmit(submit)}>
              <FormField
                control={control}
                name="subjectId"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex gap-2">
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="Search by Subject ID"
                          className="h-10"
                        />
                      </FormControl>
                      <Button type="submit" className="h-10">
                        Submit
                      </Button>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
        {subjectId !== '' && (
          <div className="flex gap-2">
            <p className="text-md text-textAccent font-semibold">Subject ID:</p>
            <p className="text-md font-semibold text-neutral-700">
              {subjectId}
            </p>
          </div>
        )}
      </Card>
      {data && data.info && (
        <Card>
          <CardHeader className="flex flex-col">
            <div className="flex justify-between">
              <CardTitle className="text-xl text-textAccent font-semibold">
                Person Data
              </CardTitle>
              <div className="flex gap-2">
                <Button
                  className="h-10 flex gap-2"
                  variant={'outline'}
                  onClick={() =>
                    navigate(AppRoutes.modifyPersonalInfo, {
                      state: {
                        subjectId: subjectId,
                        currentPersonData: data?.info.personData,
                      },
                    })
                  }
                >
                  <p>Modify Person Data</p>
                  <Edit size="22" color="#111111" variant="Outline" />
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardContent className="flex flex-col gap-10">
            {data.info.personData && <DisplayPersonalInfo data={data.info} />}
          </CardContent>
        </Card>
      )}
      {data && data.info.summary && (
        <Card>
          <CardHeader className="flex flex-col">
            <div className="flex justify-between">
              <CardTitle className="text-xl text-textAccent font-semibold">
                Summary
              </CardTitle>
              <div className="flex gap-2">
                <Button
                  className="h-10 flex gap-2"
                  variant={'outline'}
                  onClick={() =>
                    navigate(AppRoutes.modifySummary, {
                      state: {
                        subjectId: subjectId,
                        currentSummary: data?.info.summary,
                      },
                    })
                  }
                >
                  <p>Modify Summary</p>
                  <Edit size="22" color="#111111" variant="Outline" />
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardContent className="flex flex-col gap-10">
            <DisplaySummary summary={data.info.summary} />
          </CardContent>
        </Card>
      )}
      {data && data.info.socialMedia && (
        <Card>
          <CardHeader className="flex flex-col">
            <div className="flex justify-between">
              <CardTitle className="text-xl text-textAccent font-semibold">
                Social Medias
              </CardTitle>
              <div className="flex gap-2">
                {data && data.info.socialMedia && (
                  <Button
                    className="h-10 flex gap-2"
                    variant={'outline'}
                    onClick={() =>
                      navigate(AppRoutes.newSocialMedia, {
                        state: { subjectId },
                      })
                    }
                  >
                    <p>Add Social Media</p>
                    <AddSquare size="22" color="#111111" />
                  </Button>
                )}
              </div>
            </div>
          </CardHeader>
          <CardContent className="flex flex-col gap-10">
            <SocialMediaTable
              key={data.info.id}
              data={data}
              subjectId={subjectId}
            />
          </CardContent>
        </Card>
      )}

      {isLoading && !data && (
        <Card>
          <CardHeader>
            <CardTitle className="text-xl text-textAccent font-semibold">
              Social Medias
            </CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col gap-10">
            <div className="flex h-96 items-center">
              <div className="flex justify-center w-full">
                <Loader />
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      {data !== undefined && !isLoading && !data?.info.socialMedia && (
        <Card>
          <CardHeader>
            <CardTitle className="text-xl text-textAccent font-semibold">
              Social Medias
            </CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col gap-10">
            <EmptyState text="This user ID has no social media data available." />
          </CardContent>
        </Card>
      )}
      {data && postsData?.info && isSuccessPostsData && (
        <Card>
          <CardHeader className="flex flex-col">
            <div className="flex justify-between">
              <CardTitle className="text-xl text-textAccent font-semibold">
                Posts
              </CardTitle>
              <div className="flex gap-2">
                <Button
                  className="h-10 flex gap-2"
                  variant={'outline'}
                  onClick={() =>
                    navigate(AppRoutes.newPost, {
                      state: { subjectId, isWebSearch: true },
                    })
                  }
                >
                  <p>Add Web Search Post</p>
                  <AddSquare size="22" color="#111111" />
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardContent className="flex flex-col gap-10">
            {postsData.info.map((posts, index) => (
              <PostsTable
                key={index}
                data={posts.list}
                subjectId={subjectId}
                id={data.info.id}
              />
            ))}
          </CardContent>
        </Card>
      )}

      {isLoadingPostsData && !postsData && (
        <Card>
          <CardHeader>
            <CardTitle className="text-xl text-textAccent font-semibold">
              Posts
            </CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col gap-10">
            <div className="flex h-96 items-center">
              <div className="flex justify-center w-full">
                <Loader />
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      {isErrorPostsData && !postsData && !isLoadingPostsData && (
        <Card>
          <CardHeader>
            <CardTitle className="text-xl text-textAccent font-semibold">
              Posts
            </CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col gap-10">
            <EmptyState text="This user ID has no post data available." />
          </CardContent>
        </Card>
      )}
    </div>
  )
}
