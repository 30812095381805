export enum AppRoutes {
  dashboard = '/dashboard/enterprises',
  login = '/',
  test = '',
  topics = '/dashboard/topics',
  newTopic = '/dashboard/newTopic',
  socialMedia = '/dashboard/socialMedia',
  newSocialMedia = '/dashboard/newSocialMedia',
  newPost = '/dashboard/newPost',
  modifyPersonalInfo = '/dashboard/modifyPersonalInfo',
  modifySummary = '/dashboard/modifySummary',
}
