import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { useForm, useFormState } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { BackHeader } from '@/components/BackHeader/BackHeader'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Textarea } from '@/components/ui/text-area'
import { AppRoutes } from '@/constants/appRoutes'
import { useModifySummaryMutation } from '@/store/api/Subjects/Subjects.query'
import type { modifySummaryRequest } from '@/store/api/Subjects/Subjects.types'
import { filterEmptyFields } from '@/utils/filterEmptyFields'
import { modifySummarySchema } from '@/utils/schemas'
import type { modifySummarySchemaType } from '@/utils/schemas'

export const ModifySummary = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const currentSummary = state.currentSummary

  const [modifySummary, { isLoading }] = useModifySummaryMutation()

  const form = useForm<modifySummarySchemaType>({
    resolver: zodResolver(modifySummarySchema),
    defaultValues: {
      executiveAim: currentSummary.executiveAim || '',
      executiveSummary: currentSummary.executiveSummary || '',
      riskIndicators: currentSummary.riskIndicators || '',
      sentimentAnalysis: currentSummary.sentimentAnalysis || '',
      redFlags: currentSummary.redFlags || '',
      webNewsFindings: currentSummary.webNewsFindings || '',
      digitalBehavior: currentSummary.digitalBehavior || '',
      digitalInfluence: currentSummary.digitalInfluence || '',
      recommendationsImprovements:
        currentSummary.recommendationsImprovements || '',
      recommentationsConclusion: currentSummary.recommentationsConclusion || '',
      financialMisconduct: currentSummary.financialMisconduct || '',
      legalDispute: currentSummary.legalDispute || '',
      criminalRecords: currentSummary.criminalRecords || '',
      legalJudgments: currentSummary.legalJudgments || '',
      positiveMentions: currentSummary.positiveMentions || '',
      assessment: currentSummary.assessment || '',
      complianceDisclaimer: currentSummary.complianceDisclaimer || '',
      adverseMediaSummary: currentSummary.adverseMediaSummary || '',
      recommendationsInsights: currentSummary.recommendationsInsights || '',
    },
    mode: 'onChange',
  })

  const { handleSubmit, control } = form
  const { isValid } = useFormState({ control })

  const handleModifySummary = async (data: any) => {
    try {
      const requestInfo: modifySummaryRequest = {
        subjectId: state.subjectId,
        executiveAim: data.executiveAim || undefined,
        sentimentAnalysis: data.sentimentAnalysis || undefined,
        redFlags: data.redFlags || undefined,
        webNewsFindings: data.webNewsFindings || undefined,
        executiveSummary: data.executiveSummary || undefined,
        digitalBehavior: data.digitalBehavior || undefined,
        digitalInfluence: data.digitalInfluence || undefined,
        recommendationsImprovements:
          data.recommendationsImprovements || undefined,
        recommentationsConclusion: data.recommentationsConclusion || undefined,
        financialMisconduct: data.financialMisconduct || undefined,
        legalDispute: data.legalDispute || undefined,
        criminalRecords: data.criminalRecords || undefined,
        legalJudgments: data.legalJudgments || undefined,
        positiveMentions: data.positiveMentions || undefined,
        assessment: data.assessment || undefined,
        complianceDisclaimer: data.complianceDisclaimer || undefined,
        adverseMediaSummary: data.adverseMediaSummary || undefined,
        riskIndicators: data.riskIndicators,
        recommendationsInsights: data.recommendationsInsights,
      }
      const filteredRequestInfo = filterEmptyFields(requestInfo, 'subjectId')
      await modifySummary(filteredRequestInfo).unwrap()
      toast.success('Summary modified successfully.')
      navigate(AppRoutes.socialMedia, {
        state: state.subjectId,
      })
    } catch (e) {
      toast.error('Error modifying Summary.')
      console.error(e)
    }
  }

  const onSubmit = (data: any) => {
    handleModifySummary(data)
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row items-center">
            <BackHeader
              backText=""
              onPressBack={() =>
                navigate(AppRoutes.socialMedia, {
                  state: state.subjectId,
                })
              }
            />
          </div>
          <Card className="mt-8">
            <div className="p-6">
              <div className="flex justify-between lg:flex-row flex-col-reverse gap-4 mb-6">
                <p className="text-neutral-800 text-xl font-medium">
                  Modify Summary
                </p>
              </div>
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-3">
                  <FormField
                    control={control}
                    name="executiveAim"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Executive Aim</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="executiveAim" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="executiveSummary"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Executive Summary</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="executiveSummary" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="riskIndicators"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Risk Indicators</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="riskIndicators" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="sentimentAnalysis"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Sentiment Analysis</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="sentimentAnalysis" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="redFlags"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Red Flags</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="redFlags" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="webNewsFindings"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Web News Findings</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="webNewsFindings" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="digitalBehavior"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Digital Behavior</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="digitalBehavior" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="digitalInfluence"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Digital Influence</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="digitalInfluence" />
                      </FormItem>
                    )}
                  />{' '}
                  <FormField
                    control={control}
                    name="recommendationsImprovements"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Recommendations Improvements</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="recommendationsImprovements" />
                      </FormItem>
                    )}
                  />{' '}
                  <FormField
                    control={control}
                    name="recommentationsConclusion"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Recommentations Conclusion</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="recommentationsConclusion" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="recommendationsInsights"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Recommendations Insights</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="recommendationsInsights" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="financialMisconduct"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>
                          Financial Misconduct (Only on Adverse Media Report)
                        </FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="financialMisconduct" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="legalDispute"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>
                          Legal Dispute (Only on Adverse Media Report)
                        </FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="legalDispute" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="criminalRecords"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>
                          Criminal Records (Only on Adverse Media Report)
                        </FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="criminalRecords" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="legalJudgments"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>
                          Legal Judgments (Only on Adverse Media Report)
                        </FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="legalJudgments" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="positiveMentions"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>
                          Positive Mentions (Only on Adverse Media Report)
                        </FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="positiveMentions" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="assessment"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>
                          Assessment (Only on Adverse Media Report)
                        </FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="assessment" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="complianceDisclaimer"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>
                          Compliance Disclaimer (Only on Adverse Media Report)
                        </FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="complianceDisclaimer" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="adverseMediaSummary"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>
                          Adverse Media Summary (Only on Adverse Media Report)
                        </FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage data-name="adverseMediaSummary" />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex gap-6">
                  <Button
                    isLoading={isLoading}
                    type="submit"
                    disabled={!isValid}
                    className="h-12 px-5 py-3 bg-neutral-800 rounded-lg"
                  >
                    <p className="text-neutral-50 text-base font-medium tracking-wide">
                      Save
                    </p>
                  </Button>
                  <Button
                    className="w-24 h-12 px-5 py-3 bg-zinc-100 rounded-lg"
                    onClick={() =>
                      navigate(AppRoutes.socialMedia, {
                        state: state.subjectId,
                      })
                    }
                  >
                    <p className="text-neutral-800 text-base font-medium tracking-wide">
                      Cancel
                    </p>
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </form>
      </Form>
    </div>
  )
}
