import { zodResolver } from '@hookform/resolvers/zod'
import { DocumentUpload } from 'iconsax-react'
import { isEmpty } from 'ramda'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import DragAndDropEmpty from '@/assets/dragAndDropEmpty.svg?react'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useUsersQuery } from '@/store/api/Admin/Admin.query'
import { useUploadCSVMutation } from '@/store/api/Batch/Batch.query'
import type { ICsvFile } from '@/store/api/Batch/Batch.types'
import { useGetEnterprisesQuery } from '@/store/api/Enterprises/Enterprises.query'
import type { Enterprise } from '@/store/api/Enterprises/Enterprises.types'
import type { uploadCsvSchemaType } from '@/utils/schemas'
import { uploadCsvSchema } from '@/utils/schemas'

import { BatchTable } from './components/BatchTable'
import { parseCsv } from './components/csvUtils'
import { ErrorBatchTable } from './components/ErrorBatchTable'

export const BatchUpload = () => {
  const { data: userData } = useUsersQuery()
  const { data: enterpriseData } = useGetEnterprisesQuery({})
  const [filteredEnterprise, setFilteredEnterprise] = useState<Enterprise>()
  const [fileName, setFileName] = useState<string | undefined>()
  const form = useForm<uploadCsvSchemaType>({
    resolver: zodResolver(uploadCsvSchema),
    defaultValues: {
      ownerId: '',
    },
  })

  const [csvFile, setCsvFile] = useState()
  const { handleSubmit, control, watch } = form
  const ownerId = watch('ownerId')
  const [JSONFile, setJSONFile] = useState<ICsvFile[]>([])
  const [parsingError, setParsingError] = useState<string | null>(null)
  const [
    uploadCSVMutation,
    { isLoading: isLoadingCsvMutation, data: uploadCsvData },
  ] = useUploadCSVMutation()
  const handleFileUpload = (file: any) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async (e) => {
        const text = e.target?.result as string
        try {
          const parsedData = await parseCsv(text)
          setJSONFile(parsedData)
          setParsingError(null)
        } catch (errors) {
          setParsingError('Error parsing CSV file')
          console.error(parsingError)
        }
      }
      reader.readAsText(file)
    }
  }

  const onDrop = useCallback(async (acceptedFiles: any) => {
    if (!acceptedFiles || isEmpty(acceptedFiles)) return

    setFileName(acceptedFiles[0].name)
    setCsvFile(acceptedFiles[0])

    handleFileUpload(acceptedFiles[0])
  }, [])

  const reportCsvSend = async (data: any) => {
    if (csvFile && data.ownerId) {
      const formData = new FormData()
      formData.append('file', csvFile)

      try {
        await uploadCSVMutation({
          id: data.ownerId,
          file: formData,
        }).unwrap()
      } catch (error) {
        toast.error(error)
      }
    }
  }

  const onDropRejected = (e: any) => {
    if (
      !isEmpty(e) &&
      e[0].errors &&
      e[0].errors[0].code === 'file-too-large'
    ) {
      toast.error('The file size is larger than 6MB.')
    } else {
      toast.error('The format is wrong, please add another.')
    }
  }

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop,
    onDropRejected: onDropRejected,
    disabled: false,
    noClick: true,
    accept: {
      'text/csv': ['.csv'],
    },
    maxFiles: 1,
    maxSize: 6000000,
  })

  const onPressLoadFile = () => {
    // `current` points to the mounted file input element
    if (inputRef && inputRef.current) {
      inputRef.current.click()
    }
  }

  useEffect(() => {
    if (userData && enterpriseData && ownerId) {
      const user = userData.find((user) => user.id === ownerId)

      if (user) {
        const enterprise = enterpriseData.info.list.find(
          (enterprise) => enterprise.name === user.enterprise,
        )
        setFilteredEnterprise(enterprise)
      }
    }
  }, [watch('ownerId'), userData, enterpriseData])

  return (
    <>
      <div className="flex flex-col gap-6 w-full justify-center items-center max-xl:flex-col mt-8">
        <Card className={`flex justify-between flex-col w-full h-full`}>
          <div
            {...getRootProps()}
            className="flex flex-col m-2 lg:m-6  flex-1 justify-center items-center"
          >
            <div className=" flex flex-col justify-center items-center ">
              <input {...getInputProps()} />
              <div className="mb-8">
                {JSONFile.length > 0 ? (
                  <div>
                    <div className="flex flex-row gap-4 justify-between items-center mb-6">
                      <div className="flex flex-row gap-2">
                        <p>Enterprise credits: </p>
                        <p
                          className={
                            filteredEnterprise &&
                            filteredEnterprise?.credits.aiAnalystReportCredits <
                              JSONFile.length
                              ? 'text-red-500'
                              : 'text-neutral-800'
                          }
                        >
                          {filteredEnterprise?.credits.aiAnalystReportCredits}
                        </p>
                        <p>You are gona use: </p>
                        <p>{JSONFile.length}</p>
                      </div>
                      <Form {...form}>
                        <form
                          onSubmit={handleSubmit(reportCsvSend)}
                          className="flex flex-col gap-6"
                        >
                          <div className="flex flex-row gap-3">
                            <Button
                              type="button"
                              onClick={onPressLoadFile}
                              isLoading={isLoadingCsvMutation}
                              variant={'secondary'}
                            >
                              Choose File
                              <DocumentUpload size={18} className="ml-2" />
                            </Button>
                            <Button
                              type="submit"
                              className="z-50"
                              isLoading={isLoadingCsvMutation}
                              disabled={
                                filteredEnterprise &&
                                filteredEnterprise?.credits
                                  .aiAnalystReportCredits < JSONFile.length
                              }
                            >
                              Send
                            </Button>
                          </div>
                        </form>
                      </Form>
                    </div>

                    <BatchTable data={JSONFile} />
                    <div className="flex justify-end">
                      <p className="text-textAccent tracking-wider mt-6">
                        File Name: {fileName}
                      </p>
                    </div>

                    {uploadCsvData && (
                      <div>
                        <div className="flex justify-start items-start">
                          <p className="mt-6 ml-2 text-xl font-bold">Errors:</p>
                        </div>
                        <ErrorBatchTable uploadCsvData={uploadCsvData} />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-center">
                    <DragAndDropEmpty />
                    <h1 className="text-lg font-semibold tracking-wide mt-4 mb-4">
                      BackLog
                    </h1>

                    <div className="flex flex-col gap-4 justify-center items-center">
                      <Form {...form}>
                        <form
                          onSubmit={handleSubmit(reportCsvSend)}
                          className="flex flex-col gap-6"
                        >
                          <FormField
                            control={control}
                            name="ownerId"
                            render={({ field }) => (
                              <FormItem className="flex w-full rounded-md items-end h-8 gap-2  pb-1">
                                <FormLabel className="font-medium tracking-wide pb text-[16px]">
                                  Owner ID:
                                </FormLabel>
                                <FormControl className="w-full focus:outline-none">
                                  <Input
                                    {...field}
                                    placeholder="Owner ID"
                                    className="h-8 w-40 border-neutral-600"
                                  />
                                </FormControl>

                                <FormMessage data-name="ownerId" />
                              </FormItem>
                            )}
                          />
                          <div className="flex flex-col lg:flex-row items-center gap-2">
                            <p className="text-textAccent tracking-wider">
                              Add File, supported formats:
                            </p>
                            <div className="flex flex-row gap-2">
                              <div className="rounded-full py-1 px-2 bg-[#C8FFD4] text-[#1E924D] text-xs">
                                CSV
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-center items center">
                            <Button
                              type="button"
                              onClick={onPressLoadFile}
                              isLoading={isLoadingCsvMutation}
                              variant={'secondary'}
                              disabled={ownerId === ''}
                            >
                              Choose File
                              <DocumentUpload size={18} className="ml-2" />
                            </Button>
                          </div>
                        </form>
                      </Form>
                      <p className="text-textAccent">or drop files here.</p>
                      <p className="text-textAccent">Max. 6MB</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}
