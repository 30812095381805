import { EyeOpenIcon, EyeClosedIcon } from '@radix-ui/react-icons'
import * as React from 'react'
import { useState } from 'react'

import { cn } from '@/lib/utils'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  maskFormat?: string
  showPassIcon?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, maskFormat, showPassIcon, ...props }, ref) => {
    const [showPass, setShowPass] = useState(false)
    const togglePasswordVisibility = () => {
      setShowPass((prevShowPass) => !prevShowPass)
    }
    return (
      <div className="flex flex-row-reverse content-end">
        <input
          type={type === 'password' ? (showPass ? 'text' : 'password') : type}
          className={cn(
            'flex h-11 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
            className,
          )}
          ref={ref}
          {...props}
        />
        {showPassIcon && (
          <button
            className="flex absolute mt-4 mr-3"
            type="button"
            onClick={togglePasswordVisibility}
          >
            {showPass ? (
              <EyeOpenIcon width={18} height={17} color="#9CA3AF" />
            ) : (
              <EyeClosedIcon width={18} height={17} color="#9CA3AF" />
            )}
          </button>
        )}
      </div>
    )
  },
)
Input.displayName = 'Input'

export { Input }
