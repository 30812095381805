import { RTKTags, api } from '@/store/api'

import Env from '../../../env/env'

import type {
  GetEnterpriseRequest,
  GetEnterprisesResponse,
  ModifyInterpriseRequest,
} from './Enterprises.types'

export const enterprisesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    GetEnterprises: builder.query<GetEnterprisesResponse, GetEnterpriseRequest>(
      {
        query: () => ({
          url: `${Env.REACT_APP_BASE_API_URL}/api/v3/admin/enterprises`,
          method: 'GET',
          params: {
            pageSize: 9999,
          },
        }),
        providesTags: [RTKTags.enterprises],
      },
    ),
    ModifyEnterpriseInformation: builder.mutation<
      void,
      ModifyInterpriseRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/enterprises/${request.id}`,
        method: 'PATCH',
        body: {
          customPdf: request.customPdf,
          logoUrl: request.logoUrl,
          displayName: request.displayName,
          palletBgColor: request.palletBgColor,
          palletHlColor: request.palletHlColor,
          palletAcColor: request.palletAcColor,
        },
      }),
      invalidatesTags: [RTKTags.enterprises],
    }),
  }),
})

export const {
  useGetEnterprisesQuery,
  useModifyEnterpriseInformationMutation,
} = enterprisesApi
