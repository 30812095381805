import { Link } from 'react-router-dom'

import type { SidebarItemTypes } from './SidebarItem.types'

export const SidebarItem = ({
  isActive,
  name,
  icon,
  linkUrl,
  disabled,
  collapsed,
  iconDisabled,
  iconActive,
}: SidebarItemTypes) => {
  return (
    <div
      className={`flex flex-row p-3 mt-5 rounded-lg ${
        isActive
          ? 'bg-sidebarActiveBackground'
          : 'bg-background hover:bg-zinc-100'
      }`}
    >
      {disabled && (
        <div className="flex flex-row">
          <div className="mr-1.5">{iconDisabled}</div>
          {!collapsed && (
            <p
              className={`text-base leading-6 text-neutral-400 ${
                isActive ? 'text-white' : 'text-textAccent'
              }`}
            >
              {name}
            </p>
          )}
        </div>
      )}
      {!disabled && (
        <Link
          to={linkUrl}
          className="flex flex-row block w-full h-full"
          role="button"
        >
          <div className="mr-1.5">{isActive ? iconActive : icon}</div>
          {!collapsed && (
            <p
              className={`text-base leading-6 ${
                isActive ? 'text-white' : 'text-black'
              }`}
            >
              {name}
            </p>
          )}
        </Link>
      )}
    </div>
  )
}
