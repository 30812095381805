import React from 'react'

interface ReportPopUpCardTypes {
  title: string
  text: string
}

export const ReportPopUpCard = ({ title, text }: ReportPopUpCardTypes) => {
  return (
    <div className="flex flex-row gap-2 w-[48.5%] items-center">
      <p className="text-neutral-600 text-sm font-medium">{title}</p>
      <p className="text-neutral-800 text-sm font-semibold">{text}</p>
    </div>
  )
}
