import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { BackHeader } from '@/components/BackHeader/BackHeader'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Switch } from '@/components/ui/switch'
import { Textarea } from '@/components/ui/text-area'
import { AppRoutes } from '@/constants/appRoutes'
import { useAddNewSocialMediaMutation } from '@/store/api/Subjects/Subjects.query'
import type { addNewSocialMediaRequest } from '@/store/api/Subjects/Subjects.types'
import type { addSocialMediaSchemaType } from '@/utils/schemas'
import { addSocialMediaSchema } from '@/utils/schemas'

export const NewSocialMedia = () => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const [createNewSocialMedia, { isLoading }] = useAddNewSocialMediaMutation()

  const handleAddNewSocialMedia = async (data: any) => {
    const postsCount = Number(data.postsCount as string)
    const following = Number(data.following as string)
    const followers = Number(data.followers as string)

    try {
      const requestInfo: addNewSocialMediaRequest = {
        name: data.name,
        userName: data.userName,
        profileLink: data.profileLink,
        profileImgUrl: data.profileImgUrl,
        postsCount: postsCount,
        following: following,
        followers: followers,
        bio: data.bio,
        subjectId: state.subjectId,
        isPrivate: data.isPrivate,
      }
      await createNewSocialMedia(requestInfo).unwrap()
      toast.success('Social Media added successfully.')
      navigate(AppRoutes.socialMedia, {
        state: state.subjectId,
      })
    } catch (e) {
      toast.error('Error adding new Social Media.')
      console.error(e)
    }
  }

  const form = useForm<addSocialMediaSchemaType>({
    resolver: zodResolver(addSocialMediaSchema),
    defaultValues: {
      name: '',
      userName: '',
      bio: '',
      profileImgUrl: '',
      profileLink: '',
      followers: '',
      following: '',
      postsCount: '',
      isPrivate: false,
    },
    mode: 'onChange',
  })

  const { handleSubmit, control } = form

  const onSubmit = (data: any) => {
    handleAddNewSocialMedia(data)
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row items-center">
            <BackHeader
              backText=""
              onPressBack={() =>
                navigate(AppRoutes.socialMedia, {
                  state: state.subjectId,
                })
              }
            />
          </div>
          <Card className="mt-8">
            <div className="p-6">
              <div className="flex justify-between mb-1 lg:flex-row flex-col-reverse gap-4">
                <p className="text-neutral-800 text-xl font-medium">
                  Create New Social Media
                </p>
              </div>
              <div className="lg:mb-8 mb-3">
                <p className="text-neutral-500 text-base font-normal">
                  Enter the data of the new Social Media you want to create.
                </p>
              </div>
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-3">
                  <FormField
                    control={control}
                    name="name"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Name*</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Name" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="userName"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>User Name*</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="User Name" />
                        </FormControl>
                        <FormMessage data-name="userName" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="bio"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6">
                        <FormLabel>Bio*</FormLabel>
                        <FormControl>
                          <Textarea {...field} placeholder="Bio" />
                        </FormControl>
                        <FormMessage data-name="bio" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="profileLink"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6">
                        <FormLabel>Profile Link*</FormLabel>
                        <FormControl>
                          <Textarea {...field} placeholder="Profile Link" />
                        </FormControl>
                        <FormMessage data-name="profileLink" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="profileImgUrl"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6 ">
                        <FormLabel>Profile Img Url*</FormLabel>
                        <FormControl>
                          <Textarea {...field} placeholder="Profile Img Url" />
                        </FormControl>
                        <FormDescription>
                          Only enter URL of existing images.
                        </FormDescription>
                        <FormMessage data-name="profileImgUrl" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="followers"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Followers*</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Followers" />
                        </FormControl>
                        <FormMessage data-name="followers" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="following"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Following*</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Following" />
                        </FormControl>
                        <FormMessage data-name="following" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="postsCount"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Posts Count*</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Posts Count" />
                        </FormControl>
                        <FormMessage data-name="postsCount" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="isPrivate"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6 flex flex-col">
                        <FormLabel>Is Private?*</FormLabel>
                        <div className="flex gap-3">
                          <FormControl>
                            <Switch
                              className="w-11 h-6"
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                          <p className="text-neutral-800 text-medium font-medium">
                            {field.value ? (
                              <p className="text-green-600">Yes</p>
                            ) : (
                              <p className="text-red-600">No</p>
                            )}
                          </p>
                        </div>
                        <FormMessage data-name="isPrivate" />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex gap-6">
                  <Button
                    isLoading={isLoading}
                    type="submit"
                    disabled={!form.formState.isValid}
                    className="h-12 px-5 py-3 bg-neutral-800 rounded-lg"
                  >
                    <p className="text-neutral-50 text-base font-medium tracking-wide">
                      Add Social Media
                    </p>
                  </Button>
                  <Button
                    className="w-24 h-12 px-5 py-3 bg-zinc-100 rounded-lg"
                    onClick={() =>
                      navigate(AppRoutes.socialMedia, {
                        state: state.subjectId,
                      })
                    }
                  >
                    <p className="text-neutral-800 text-base font-medium tracking-wide">
                      Cancel
                    </p>
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </form>
      </Form>
    </div>
  )
}
