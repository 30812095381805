export default {
  NODE_ENV: import.meta.env.VITE_USER_NODE_ENV || 'development',
  REACT_APP_BASE_API_URL: import.meta.env.VITE_BASE_API_URL,
  REACT_APP_REAL_STATE: import.meta.env.VITE_REACT_APP_REAL_STATE,
  FIREBASE_CONFIG_APIKEY: import.meta.env.VITE_FIREBASE_CONFIG_APIKEY,
  FIREBASE_CONFIG_AUTHDOMAIN: import.meta.env.VITE_FIREBASE_CONFIG_AUTHDOMAIN,
  FIREBASE_CONFIG_PROJECTID: import.meta.env.VITE_FIREBASE_CONFIG_PROJECTID,
  FIREBASE_CONFIG_STORAGEBUCKET: import.meta.env
    .VITE_FIREBASE_CONFIG_STORAGEBUCKET,
  FIREBASE_CONFIG_MESSAGINGSENDERID: import.meta.env
    .VITE_FIREBASE_CONFIG_MESSAGINGSENDERID,
  FIREBASE_CONFIG_APPID: import.meta.env.VITE_FIREBASE_CONFIG_APPID,
  FIREBASE_CONFIG_MEASUREMENTID: import.meta.env
    .VITE_FIREBASE_CONFIG_MEASUREMENTID,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
}
