import EmptyStateImage from '@/assets/dashboard/recentSearches/emptyState.svg?react'
import { Card, CardContent } from '@/components/ui/card'

import type { EmptyStateTypes } from './EmptyStateTypes'

export const EmptyState = ({ text }: EmptyStateTypes) => {
  return (
    <Card className="lg:h-3/4 flex justify-center items-center">
      <CardContent>
        <div
          id="emptyState"
          className="flex flex-col justify-center items-center p-8"
        >
          <EmptyStateImage />
          <p className="text-base font-medium tracking-wide my-6 text-center neutral-500">
            {text}
          </p>
        </div>
      </CardContent>
    </Card>
  )
}
