import { Eye } from 'iconsax-react'
import { Link } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export const SocialMediaPopUp = ({
  bio,
  profileImgUrl,
  profileLink,
}: {
  bio: string
  profileImgUrl: string
  profileLink: string
}) => {
  return (
    <Dialog>
      <DialogTrigger>
        <Button
          className="w-10 h-10 bg-gray-200 rounded-[100px] justify-center items-center gap-1 mr-2"
          size={'icon'}
        >
          <Eye
            width={22}
            height={22}
            variant="Outline"
            color={weCheckPalette.icon.disableIcon}
          />
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[60%]">
        <DialogHeader>
          <DialogTitle>Bio</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <p className="text-wrap">{bio || 'No Bio available'}</p>
        </DialogDescription>
        <DialogHeader>
          <DialogTitle>Pic URL</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {profileImgUrl ? (
            <Link
              to={profileImgUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-cyan-600"
            >
              {profileImgUrl}
            </Link>
          ) : (
            <p>No Profile Img Url available</p>
          )}
        </DialogDescription>
        <DialogHeader>
          <DialogTitle>Profile URL</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {profileLink ? (
            <Link
              to={profileLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-cyan-600"
            >
              {profileLink || 'No Profile Link available'}
            </Link>
          ) : (
            <p>No Profile Link available</p>
          )}
        </DialogDescription>
      </DialogContent>
    </Dialog>
  )
}
