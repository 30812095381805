import React from 'react'
import { Provider as ReduxStoreProvider } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import { HistoryRouter } from 'redux-first-history/rr6'

import { Toaster } from '@/components/ui/sonner'

import { initializeSentry } from './InitializeSentry/InitializeSentry'
import { BatchUpload } from './pages/BackLog/BatchUpload'
import { Error404 } from './pages/Dashboard/components/ContactCard/Error404'
import { FeedBackScreen } from './pages/Dashboard/components/FeedBackScreen/FeedBackScreen'
import { Dashboard } from './pages/Dashboard/Dashboard'
import { Enterprises } from './pages/Enterprises/Enterprises'
import { Login } from './pages/Login/Login'
import { LoginForm } from './pages/Login/LoginForm'
import { Reports } from './pages/Reports/Reports'
import { ModifyPersonalInfo } from './pages/Subject/ModifyPersonalInfo'
import { ModifySummary } from './pages/Subject/ModifySummary'
import { NewPost } from './pages/Subject/NewPost'
import { NewSocialMedia } from './pages/Subject/NewSocialMedia'
import { Subject } from './pages/Subject/Subject'
import { NewTopic } from './pages/Topics/NewTopic'
import { Topics } from './pages/Topics/Topics'
import { Users } from './pages/Users/Users'
import { history, store } from './store/store'

initializeSentry()

const App: React.FC = () => {
  return (
    <ReduxStoreProvider store={store}>
      <HistoryRouter history={history}>
        <Routes>
          <Route path="" element={<Login />}>
            <Route path="/" element={<LoginForm />} />
          </Route>

          <Route path="dashboard" element={<Dashboard />}>
            <Route path="error404" element={<Error404 />} />
            <Route path="feedBackScreen" element={<FeedBackScreen />} />
            <Route path="enterprises" index element={<Enterprises />} />
            <Route path="batchUpload" index element={<BatchUpload />} />
            <Route path="users" index element={<Users />} />
            <Route path="reports" index element={<Reports />} />
            <Route path="topics" index element={<Topics />} />
            <Route path="newTopic" index element={<NewTopic />} />
            <Route path="socialMedia" index element={<Subject />} />
            <Route path="newSocialMedia" index element={<NewSocialMedia />} />
            <Route path="newPost" index element={<NewPost />} />
            <Route
              path="modifyPersonalInfo"
              index
              element={<ModifyPersonalInfo />}
            />
            <Route path="modifySummary" index element={<ModifySummary />} />
          </Route>

          <Route path="*" element={<Error404 />} />
        </Routes>
      </HistoryRouter>

      <Toaster richColors />
    </ReduxStoreProvider>
  )
}

export default App
