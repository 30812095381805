import { Eye } from 'iconsax-react'
import { Link } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface PostsPopUpInterface {
  link: string
  content: string
  flagged: boolean
  flaggedText: string
  mediaUrl: string
  videoLink: string
  sentimentReason: string
  flagReason: string
}

export const PostsPopUp = ({
  link,
  content,
  flagged,
  flaggedText,
  mediaUrl,
  videoLink,
  sentimentReason,
  flagReason,
}: PostsPopUpInterface) => {
  return (
    <Dialog>
      <DialogTrigger>
        <Button
          className="w-10 h-10 bg-gray-200 rounded-[100px] justify-center items-center gap-1 mr-2"
          size={'icon'}
        >
          <Eye
            width={22}
            height={22}
            variant="Outline"
            color={weCheckPalette.icon.disableIcon}
          />
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[60%]">
        <DialogHeader>
          <DialogTitle>Is Flagged?</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <p className="text-wrap">{flagged ? 'Yes' : 'No'}</p>
        </DialogDescription>
        <DialogHeader>
          <DialogTitle>Flagged Text</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <p>{flaggedText || '-'}</p>
        </DialogDescription>
        <DialogHeader>
          <DialogTitle>Flag Reason</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <p>{flagReason || '-'}</p>
        </DialogDescription>
        <DialogHeader>
          <DialogTitle>Sentiment Reason</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <p>{sentimentReason || '-'}</p>
        </DialogDescription>
        <DialogHeader>
          <DialogTitle>Content</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <p>{content || '-'}</p>
        </DialogDescription>

        <DialogHeader>
          <DialogTitle>Link</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {link ? (
            <Link
              to={link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-cyan-600"
            >
              {link || 'No Profile Link available'}
            </Link>
          ) : (
            <p>No Profile Link available</p>
          )}
        </DialogDescription>
        <DialogHeader>
          <DialogTitle>Profile URL</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {mediaUrl ? (
            <Link
              to={mediaUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-cyan-600"
            >
              {mediaUrl || 'No Media Link available'}
            </Link>
          ) : (
            <p>No Media Link available</p>
          )}
        </DialogDescription>
        <DialogHeader>
          <DialogTitle>Profile URL</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {videoLink ? (
            <Link
              to={videoLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-cyan-600"
            >
              {videoLink || 'No Video Link available'}
            </Link>
          ) : (
            <p>No Video Link available</p>
          )}
        </DialogDescription>
      </DialogContent>
    </Dialog>
  )
}
