export const weCheckPalette = {
  background: '#FAFAFA',
  homeBackground: '#F2E9FF',
  backgroundHighlight: '#F4F4F4',
  sidebarActiveBackground: '#323232',
  textAccent: '#747474',
  text: '#252525',
  white: '#FFFFFF',
  logout: '#E84040',
  chipBackground: '#C6F5FF',
  chipText: '#037797',
  appGradient: {
    start: '#CAE7EC',
    end: '#F6E6F6',
  },
  buttonPrincipalBackground: '#252525',
  icon: {
    fillBackground: '#EDEDED',
    disableIcon: '#9D9D9D',
  },
  linkColor: '#479BB2',
}
