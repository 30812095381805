import { zodResolver } from '@hookform/resolvers/zod'
import { DollarCircle } from 'iconsax-react'
import { useForm, Controller } from 'react-hook-form'
import { toast } from 'sonner'

import AdverseIcon from '@/assets/credits/adverse.svg?react'
import AiAnalystIcon from '@/assets/credits/ai+analyst.svg?react'
import AiIcon from '@/assets/credits/ai.svg?react'
import AssistedIcon from '@/assets/credits/assisted.svg?react'
import FacialIcon from '@/assets/credits/facial.svg?react'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import type { Enterprise } from '@/store/api/Enterprises/Enterprises.types'
import { useAddCreditsMutation } from '@/store/api/Payments/Payments.query'
import { ETypeCredits } from '@/store/api/Payments/Payments.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import type { addCreditSchemaType } from '@/utils/schemas'
import { addCreditSchema } from '@/utils/schemas'

import { BalanceCard } from './BalanceCard'

export const AddCredits = ({ enterprise }: { enterprise?: Enterprise }) => {
  const [addCredits, { isLoading }] = useAddCreditsMutation()

  const form = useForm<addCreditSchemaType>({
    resolver: zodResolver(addCreditSchema),
    defaultValues: {
      quantity: 0,
      type: '',
    },
  })

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = form

  const onSubmit = async (data: addCreditSchemaType) => {
    const requestInfo = {
      id: enterprise?.id || '',
      quantity: data.quantity,
      type: data.type as ETypeCredits,
    }

    try {
      await addCredits(requestInfo).unwrap()
      form.reset()
      toast.success('Credits added successfully.')
    } catch (e) {
      toast.error('Error adding credits.')
      catchWithSentry(e)
    }
  }

  return (
    <Dialog>
      <DialogTrigger>
        <Button
          className="w-10 h-10 bg-gray-200 rounded-[100px] justify-center items-center gap-1 mr-2"
          size={'icon'}
        >
          <DollarCircle
            width={22}
            height={22}
            variant="Outline"
            color={weCheckPalette.icon.disableIcon}
          />
        </Button>
      </DialogTrigger>
      <DialogContent className="flex">
        <div className="flex flex-col gap-4">
          <DialogHeader>
            <DialogTitle>Current Credits Balance</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-1">
            <BalanceCard
              name={'Facial Mathing'}
              credits={enterprise?.credits.facialMatchingCredits}
              icon={<FacialIcon width={24} />}
            />
            <BalanceCard
              name={'Adverse Media Report'}
              credits={enterprise?.credits.adverseMediaReportCredits}
              icon={<AdverseIcon width={24} />}
            />
            <BalanceCard
              name={'Ai Report'}
              credits={enterprise?.credits.aiReportCredits}
              icon={<AiIcon width={24} />}
            />
            <BalanceCard
              name={'Ai Analyst Report'}
              credits={enterprise?.credits.aiAnalystReportCredits}
              icon={<AiAnalystIcon width={24} />}
            />
            <BalanceCard
              name={'Assisted Report'}
              credits={enterprise?.credits.assistedReportCredits}
              icon={<AssistedIcon width={24} />}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <DialogHeader>
            <DialogTitle>Add Credits</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-1">
            <Form {...form}>
              <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
                <div className="flex flex-col gap-4 ">
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <SelectTrigger className="w-50 h-8 border-neutral-600">
                          <SelectValue placeholder="Select Credit type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value={ETypeCredits.ASSISTED_REPORT}>
                            We Check Assisted
                          </SelectItem>
                          <SelectItem value={ETypeCredits.ANALYST_REPORT}>
                            AI Analyst Report
                          </SelectItem>
                          <SelectItem value={ETypeCredits.AI_REPORT}>
                            AI Report
                          </SelectItem>
                          <SelectItem value={ETypeCredits.ADVERSE_MEDIA_REPORT}>
                            Adverse Media
                          </SelectItem>
                          <SelectItem value={ETypeCredits.FACIAL_MATCHING}>
                            Facial Matching
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    )}
                  />
                  <FormField
                    control={control}
                    name="quantity"
                    render={({ field }) => (
                      <FormItem className="flex w-full rounded-md items-end h-8 gap-2">
                        <FormControl className="w-full focus:outline-none">
                          <Input
                            type="number"
                            {...field}
                            placeholder="Add Credits"
                            className="h-8 w-50 border-neutral-600"
                          />
                        </FormControl>
                        <FormMessage data-name="quantity" />
                      </FormItem>
                    )}
                  />
                </div>
                <Button
                  className="py-3 px-5 font-medium tracking-widest h-11 mt-10"
                  isLoading={isLoading}
                  disabled={!isDirty || !isValid}
                >
                  Save Changes
                </Button>
              </form>
            </Form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
