import React from 'react'

interface PersonalInfoCardTypes {
  keyName: string
  value: string
}

export const PersonalInfoCard = ({ keyName, value }: PersonalInfoCardTypes) => {
  return (
    <div className="flex gap-2 w-96">
      <p className="text-neutral-500 text-base font-normal tracking-tight">
        {keyName}:
      </p>
      <p className="text-neutral-800 text-base font-semibold tracking-tight">
        {value}
      </p>
    </div>
  )
}
