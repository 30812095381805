import { Logout } from 'iconsax-react'

import { useLogout } from '@/hooks/useLogout'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import type { SidebarLogoutTypes } from './sidebarLogout.types'

export const SidebarLogout = ({ collapsed }: SidebarLogoutTypes) => {
  const { logout } = useLogout()
  return (
    <div className={`flex flex-row p-3 mt-5 rounded-lg bg-background`}>
      <div
        className="flex flex-row"
        onClick={logout}
        role="button"
        tabIndex={0}
      >
        <div className="mr-1.5">
          <Logout color={weCheckPalette.logout} />
        </div>
        {!collapsed && (
          <p className={`text-base leading-6 text-logout`}>Sign Out</p>
        )}
      </div>
    </div>
  )
}
