import BetaDistinctionDev from '@/assets/BetaDistinctionDev.svg?react'
import DevW from '@/assets/BetaDistinctionDevCollapse.svg?react'
import BetaDistinctionProd from '@/assets/BetaDistinctionProd.svg?react'
import BetaW from '@/assets/BetaDistinctionProdCollapse.svg?react'

import type { ProdDevAssetsTypes } from './WecheckEnviromentDistinction.types'

export const WecheckEnviromentDistinction = ({
  enviroment,
  collapsed,
}: ProdDevAssetsTypes) => {
  return (
    <>
      {enviroment === 'production' ? (
        <div className="flex flex-row mb-4">
          {collapsed ? (
            <div>
              <BetaW />
            </div>
          ) : (
            <div>
              <BetaDistinctionProd />
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-row mb-4">
          {collapsed ? (
            <div>
              <DevW />
            </div>
          ) : (
            <div>
              <BetaDistinctionDev />
            </div>
          )}
        </div>
      )}
    </>
  )
}
