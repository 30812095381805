import { useState } from 'react'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

interface ConfirmActionPopUpTypes {
  TriggerText: string
  Title: string
  Description: string
  ConfirmText: string
  onClick: () => void
  isLoading: boolean
  open?: boolean
  setOpen?: any
}

export const ConfirmActionPopUp = ({
  TriggerText,
  Title,
  Description,
  ConfirmText,
  onClick,
  isLoading,
  open,
  setOpen,
}: ConfirmActionPopUpTypes) => {
  const [check, setCheck] = useState(false)

  const handleCheck = (newChecked: boolean) => {
    setCheck(newChecked)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button className="py-3 px-5 font-medium tracking-widest h-11 w-full flex">
          {TriggerText}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader className="flex gap-2">
          <DialogTitle>{Title}</DialogTitle>
          <DialogDescription>{Description}</DialogDescription>
          <DialogDescription className="flex gap-2">
            <p className="font-medium">Yes, I'm sure</p>
            <Checkbox
              className="w-5 h-5 rounded-md border-neutral-600"
              onCheckedChange={handleCheck}
            />
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            isLoading={isLoading}
            onClick={onClick}
            disabled={!check}
            className="py-3 px-5 font-medium tracking-widest h-11 w-full flex"
          >
            {ConfirmText}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
