import { Eye } from 'iconsax-react'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import type { translation } from '@/store/api/Topics/Topics.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface TopicsPopUpTypes {
  description?: string
  prompt?: string
  translations?: translation[]
}

export const TopicsPopUp = ({
  description,
  prompt,
  translations,
}: TopicsPopUpTypes) => {
  return (
    <Dialog>
      <DialogTrigger>
        <Button
          className="w-10 h-10 bg-gray-200 rounded-[100px] justify-center items-center gap-1 mr-2"
          size={'icon'}
        >
          <Eye
            width={22}
            height={22}
            variant="Outline"
            color={weCheckPalette.icon.disableIcon}
          />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Description</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <div className="flex flex-col gap-3 text-neutral-800 text-sm font-medium">
            <div className="flex flex-row gap-3">
              <p className="text-neutral-500 text-sm font-medium">
                {description}
              </p>
            </div>
          </div>
        </DialogDescription>
        <DialogHeader>
          <DialogTitle>Prompt</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <div className="flex flex-col gap-3 text-neutral-800 text-sm font-medium">
            <div className="flex flex-row gap-3">
              <p className="text-neutral-500 text-sm font-medium">{prompt}</p>
            </div>
          </div>
        </DialogDescription>
        {translations && translations.length > 0 && (
          <div>
            <DialogHeader>
              <DialogTitle className="mb-4">Translations</DialogTitle>
            </DialogHeader>
            <DialogDescription>
              <div className="flex flex-col gap-4 text-neutral-800 text-sm font-medium">
                {translations.map((translation, index) => (
                  <div key={index}>
                    <div className="flex gap-2">
                      <p>Language:</p>
                      <p className="text-neutral-500 text-sm font-medium">
                        {translation.language}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p>Name:</p>
                      <p className="text-neutral-500 text-sm font-medium">
                        {translation.name}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p>Description:</p>
                      <p className="text-neutral-500 text-sm font-medium">
                        {translation.description}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p>Keywords:</p>
                      <p className="text-neutral-500 text-sm font-medium">
                        {translation.keywords?.join(', ')}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </DialogDescription>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}
