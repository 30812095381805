import { zodResolver } from '@hookform/resolvers/zod'
import { format } from 'date-fns'
import { Edit } from 'iconsax-react'
import { is } from 'ramda'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Textarea } from '@/components/ui/text-area'
import {
  useModifyPostMutation,
  useModifyWebSearchPostMutation,
} from '@/store/api/Subjects/Subjects.query'
import { filterEmptyFields } from '@/utils/filterEmptyFields'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import type { modifyPostSchemaType } from '@/utils/schemas'
import { modifyPostSchema } from '@/utils/schemas'

interface ModifySocialMediaPopUpTypes {
  currentData: any
  id: string
  subjectId: string
  postId: string
}

export const ModifyPostPopUp = ({
  currentData,
  id,
  subjectId,
  postId,
}: ModifySocialMediaPopUpTypes) => {
  const [modifyPost, { isLoading }] = useModifyPostMutation()
  const [modifyWebSearchPost, { isLoading: isLoadingModifyWebSearchPost }] =
    useModifyWebSearchPostMutation()
  const [selectedType, setSelectedType] = useState<string>(currentData.type)
  const [selectedSentiment, setSelectedSentiment] = useState<string>(
    currentData.sentimentFlag,
  )
  const [startDate, setStartDate] = useState(currentData.date)

  const form = useForm<modifyPostSchemaType>({
    resolver: zodResolver(modifyPostSchema),
    defaultValues: {
      content: currentData.content,
      link: currentData.link,
      mediaUrl: currentData.mediaUrl !== null ? currentData.mediaUrl : '',
      videoLink: currentData.videoLink !== null ? currentData.videoLink : '',
      flags: currentData.flags,
      flaggedText: currentData.flaggedText,
      flagReason: currentData.flagReason !== null ? currentData.flagReason : '',
      sentimentReason:
        currentData.sentimentReason !== null ? currentData.sentimentReason : '',
    },
    mode: 'onChange',
  })

  const { handleSubmit, control } = form

  const handleTypeChange = (value: string) => {
    setSelectedType(value as string)
  }
  const handleSentimentChange = (value: string) => {
    setSelectedSentiment(value as string)
  }

  const handleModifyPost = async (data: any) => {
    const formattedDate = format(startDate, 'MM-dd-yyyy').toString()

    try {
      const requestData = {
        id: id,
        subjectId: subjectId,
        postId: postId,
        content: data.content,
        link: data.link,
        mediaUrl: data.mediaUrl,
        videoLink: data.videoLink,
        type: selectedType,
        sentimentFlag: selectedSentiment,
        flags: is(Array, data.flags)
          ? data.flags
          : data.flags === ''
            ? []
            : data.flags.split(',').map((flag: any) => flag.trim()),
        flaggedText: data.flaggedText,
        flagReason: data.flagReason,
        sentimentReason: data.sentimentReason,
        date: formattedDate,
      }

      const filteredRequestData = filterEmptyFields(
        requestData,
        'subjectId',
        'postId',
      )
      await modifyPost(filteredRequestData).unwrap()
      toast.success('Post modified successfully.')
    } catch (e) {
      toast.error('Error modifying the Post.')
      console.error(e)
    }
  }

  const handleModifyWebSearchPost = async (data: any) => {
    try {
      const requestData = {
        id: id,
        subjectId: subjectId,
        postId: postId,
        content: data.content,
        link: data.link,
        mediaUrl: data.mediaUrl,
        videoLink: data.videoLink,
        sentimentFlag: selectedSentiment,
        flags: is(Array, data.flags)
          ? data.flags
          : data.flags === ''
            ? []
            : data.flags.split(',').map((flag: any) => flag.trim()),
        flaggedText: data.flaggedText,
        flagReason: data.flagReason,
        sentimentReason: data.sentimentReason,
      }

      const filteredRequestData = filterEmptyFields(
        requestData,
        'subjectId',
        'postId',
      )
      await modifyWebSearchPost(filteredRequestData).unwrap()
      toast.success('Web Search Post modified successfully.')
    } catch (e) {
      toast.error('Error modifying the Web Search Post.')
      console.error(e)
    }
  }

  const submitForm = async (data: any) => {
    if (currentData.type === 'WebSearch') {
      await handleModifyWebSearchPost(data)
    } else {
      await handleModifyPost(data)
    }
  }

  return (
    <Dialog>
      <DialogTrigger>
        <Button
          className="w-10 h-10 bg-gray-200 rounded-[100px] justify-center items-center gap-1 mr-2"
          size={'icon'}
        >
          <Edit
            size="24"
            color={weCheckPalette.icon.disableIcon}
            variant="Outline"
          />
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[80%] max-h-[90%] overflow-y-scroll">
        <DialogHeader>
          <DialogTitle>
            {currentData.type === 'WebSearch'
              ? 'Modify Web Search Post Data'
              : 'Modify Post Data'}
          </DialogTitle>
        </DialogHeader>
        <div>
          <Form {...form}>
            <form onSubmit={handleSubmit(submitForm)}>
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-3">
                  <FormField
                    control={control}
                    name="content"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Content</FormLabel>
                        <FormControl>
                          <Textarea {...field} placeholder="Content" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <>
                    <FormLabel>Date*</FormLabel>
                    <div className="flex items-center justify-center">
                      <DatePicker
                        className="text-start h-8 border rounded-md text-sm px-4 py-4"
                        selected={startDate}
                        showTimeSelect
                        onChange={(date: any) => setStartDate(date)}
                      />
                    </div>
                  </>

                  <FormItem className="w-full ">
                    <FormLabel>Type*</FormLabel>
                    <Select
                      onValueChange={handleTypeChange}
                      value={selectedType}
                      defaultValue={currentData.type}
                    >
                      <SelectTrigger className="w-full h-10">
                        <SelectValue placeholder="Select product type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>Types</SelectLabel>
                          <SelectItem value={'Like'}>Like</SelectItem>
                          <SelectItem value={'Post'}>Post</SelectItem>
                          <SelectItem value={'Repost'}>Repost</SelectItem>

                          <SelectItem value={'Reply'}>Reply</SelectItem>
                          <SelectItem value={'WebSearch'}>
                            Web Search
                          </SelectItem>
                          <SelectItem value={'CustomUrl'}>
                            Custom URL
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </FormItem>

                  <FormField
                    control={control}
                    name="link"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6">
                        <FormLabel>Link*</FormLabel>
                        <FormControl>
                          <Textarea {...field} placeholder="Link" />
                        </FormControl>
                        <FormMessage data-name="link" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="mediaUrl"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6">
                        <FormLabel>Media Url</FormLabel>
                        <FormControl>
                          <Textarea {...field} placeholder="Media Url" />
                        </FormControl>
                        <FormMessage data-name="mediaUrl" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="videoLink"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6 ">
                        <FormLabel>Video Link</FormLabel>
                        <FormControl>
                          <Textarea {...field} placeholder="Video Link" />
                        </FormControl>
                        <FormMessage data-name="videoLink" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="flags"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Flags</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Flags" />
                        </FormControl>
                        <FormDescription>
                          Enter the flags as a comma-separated list (e.g.
                          "flag1, flag2, flag3").
                          <Link
                            className="ml-1 font-bold text-sky-700"
                            to={
                              'https://docs.google.com/spreadsheets/d/1jy1s1wWoEAL5eJxE3TEIfOSCJNp8KiI68n6d2qWTLH0/edit?gid=0#gid=0'
                            }
                          >
                            Click here to see the list of available topics.
                          </Link>
                        </FormDescription>
                        <FormMessage data-name="flags" />
                      </FormItem>
                    )}
                  />

                  <FormItem className="w-full ">
                    <FormLabel>Sentiment Flag*</FormLabel>
                    <Select
                      onValueChange={handleSentimentChange}
                      value={selectedSentiment}
                      defaultValue={currentData.sentimentFlag}
                    >
                      <SelectTrigger className="w-full h-10">
                        <SelectValue placeholder="Select product type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>Sentiment Flag</SelectLabel>
                          <SelectItem value={'Positive'}>Positive</SelectItem>
                          <SelectItem value={'Negative'}>Negative</SelectItem>
                          <SelectItem value={'Neutral'}>Neutral</SelectItem>

                          <SelectItem value={'Flagged'}>Flagged</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </FormItem>
                  <FormField
                    control={control}
                    name="sentimentReason"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Sentiment Reason</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Sentiment Reason" />
                        </FormControl>
                        <FormMessage data-name="sentimentReason" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="flagReason"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Flag Reason</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Flag Reason" />
                        </FormControl>
                        <FormMessage data-name="flagReason" />
                      </FormItem>
                    )}
                  />
                </div>
                <DialogFooter>
                  <div className="flex gap-6">
                    <Button
                      isLoading={isLoading || isLoadingModifyWebSearchPost}
                      type="submit"
                      className="h-11 px-5 py-3 bg-neutral-800 rounded-lg"
                    >
                      <p className="text-neutral-50 text-base font-medium tracking-wide">
                        Modify Post
                      </p>
                    </Button>
                  </div>
                </DialogFooter>
              </div>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
