import React from 'react'

interface SummaryCardTypes {
  title: string
  text?: string
}

export const SummaryCard = ({ title, text }: SummaryCardTypes) => {
  return (
    <div className="w-full p-4 bg-zinc-100 rounded-xl flex-col">
      <p className="text-neutral-800 text-xl font-medium tracking-wide">
        {title}
      </p>
      <p className="text-neutral-500 text-base font-normal tracking-tight">
        {text}
      </p>
    </div>
  )
}
