import { zodResolver } from '@hookform/resolvers/zod'
import { SearchNormal1 } from 'iconsax-react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetTitle,
} from '@/components/ui/sheet'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useDisableBasedOnPermissions } from '@/hooks/useDisableBasedOnPermissions'
import { useModifyEnterpriseInformationMutation } from '@/store/api/Enterprises/Enterprises.query'
import type { Enterprise } from '@/store/api/Enterprises/Enterprises.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import type { enterprisesSchemaType } from '@/utils/schemas'
import { enterprisesSchema } from '@/utils/schemas'

interface EntreprisesSheetTypes {
  enterprise: Enterprise
}

export const EnterprisesSheet = ({ enterprise }: EntreprisesSheetTypes) => {
  const permissions = useDisableBasedOnPermissions()
  const [open, setOpen] = useState(false)
  const [modifyEnterprise, { isLoading }] =
    useModifyEnterpriseInformationMutation()

  const [customPdf, setPdfIsChecked] = useState(enterprise.customPdf)

  const handlePdfCheckboxChange = (newChecked: boolean) => {
    setPdfIsChecked(newChecked)
  }

  const form = useForm<enterprisesSchemaType>({
    resolver: zodResolver(enterprisesSchema),
    defaultValues: {
      socialAnalysisCredits: '',
      facialMatchingCredits: '',
      logoUrl: '',
      whiteLabelingName: '',
      backgroundColor: '',
      highlightColor: '',
      accentColor: '',
    },
  })
  const { handleSubmit, control } = form

  const saveChanges = async (data: any) => {
    const requestInfo = {
      id: enterprise.id,
      customPdf,
      logoUrl: data.logoUrl,
      displayName: data.whiteLabelingName,
      palletBgColor: data.backgroundColor,
      palletHlColor: data.highlightColor,
      palletAcColor: data.accentColor,
    }
    try {
      await modifyEnterprise(requestInfo).unwrap()
      form.reset()
      toast.success('Changes saved successfully.')
    } catch (e) {
      toast.error('Error saving changes.')
      catchWithSentry(e)
    }
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger disabled={!permissions.modifyEnterprise}>
        <Button
          disabled={!permissions.modifyEnterprise}
          className="w-10 h-10 bg-gray-200 rounded-[100px] justify-center items-center gap-1 "
          size={'icon'}
        >
          <SearchNormal1
            width={22}
            height={22}
            variant="Outline"
            color={weCheckPalette.icon.disableIcon}
          />
        </Button>
      </SheetTrigger>
      <SheetContent className="min-w-[60vw] flex flex-col">
        <div className="flex flex-col">
          <SheetTitle className="text-neutral-800 text-xl font-medium tracking-tight">
            Current Configuration
          </SheetTitle>
          <div className="flex mt-4">
            <div className="flex gap-2 h-8 justify-start items-center w-full">
              <p className="text-neutral-800 text-md font-medium tracking-wide">
                Pending Review State:
              </p>
              <p
                className={`font-medium tracking-wide ${
                  enterprise.customPdf ? 'text-green-600' : 'text-red-600'
                }`}
              >
                {enterprise.customPdf ? 'Yes' : 'No'}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-4">
          <SheetTitle className="text-neutral-800 text-xl font-medium tracking-tight">
            Change Configuration
          </SheetTitle>
          <div className="flex mt-4">
            <div className="flex gap-2 h-8 justify-start items-center w-full">
              <p className="text-neutral-800 text-md font-medium tracking-wide">
                Pending Review State:
              </p>
              <Checkbox
                className="w-5 h-5 rounded-md border-neutral-600"
                onCheckedChange={handlePdfCheckboxChange}
                defaultChecked={enterprise.customPdf}
              />
            </div>
          </div>
          <Form {...form}>
            <form
              onSubmit={handleSubmit(saveChanges)}
              className="flex flex-col mt-2"
            >
              <div className="flex flex-col mt-10">
                <SheetTitle className="text-neutral-800 text-xl font-medium tracking-tight">
                  PDF Configuration
                </SheetTitle>
                <div className="flex mt-4">
                  <FormField
                    disabled={!permissions.handleEnterprises}
                    control={control}
                    name="logoUrl"
                    render={({ field }) => (
                      <FormItem className="flex w-full rounded-md items-end h-8 gap-2">
                        <FormLabel className="text-neutral-800 text-md font-medium tracking-wide">
                          Logo URL:
                        </FormLabel>
                        <FormControl className="w-full focus:outline-none">
                          <Input
                            {...field}
                            placeholder="Logo URL"
                            className="h-8 w-40 border-neutral-600"
                          />
                        </FormControl>

                        <FormMessage data-name="credits" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    disabled={!permissions.handleEnterprises}
                    control={control}
                    name="whiteLabelingName"
                    render={({ field }) => (
                      <FormItem className="flex w-full rounded-md items-end h-8 gap-2">
                        <FormLabel className="text-neutral-800 text-md font-medium tracking-wide">
                          Labeling Name:
                        </FormLabel>
                        <FormControl className="w-full focus:outline-none">
                          <Input
                            {...field}
                            placeholder="Labeling Name"
                            className="h-8 w-40 border-neutral-600"
                          />
                        </FormControl>

                        <FormMessage data-name="credits" />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex mt-2">
                  <FormField
                    disabled={!permissions.handleEnterprises}
                    control={control}
                    name="backgroundColor"
                    render={({ field }) => (
                      <FormItem className="flex w-full rounded-md items-end h-8 gap-2">
                        <FormLabel className="text-neutral-800 text-md font-medium tracking-wide">
                          Background Color:
                        </FormLabel>
                        <FormControl className="w-full focus:outline-none">
                          <Input
                            {...field}
                            placeholder="Background Color"
                            className="h-8 w-40 border-neutral-600"
                          />
                        </FormControl>

                        <FormMessage data-name="credits" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    disabled={!permissions.handleEnterprises}
                    control={control}
                    name="highlightColor"
                    render={({ field }) => (
                      <FormItem className="flex w-full rounded-md items-end h-8 gap-2">
                        <FormLabel className="text-neutral-800 text-md font-medium tracking-wide">
                          Highlight Color:
                        </FormLabel>
                        <FormControl className="w-full focus:outline-none">
                          <Input
                            {...field}
                            placeholder="Highlight Color"
                            className="h-8 w-40 border-neutral-600"
                          />
                        </FormControl>

                        <FormMessage data-name="credits" />
                      </FormItem>
                    )}
                  />
                </div>

                <FormField
                  disabled={!permissions.handleEnterprises}
                  control={control}
                  name="accentColor"
                  render={({ field }) => (
                    <FormItem className="flex w-full rounded-md items-end h-8 gap-2 mt-2">
                      <FormLabel className="text-neutral-800 text-md font-medium tracking-wide">
                        Accent Color:
                      </FormLabel>
                      <FormControl className="w-full focus:outline-none">
                        <Input
                          {...field}
                          placeholder="Highlight Color"
                          className="h-8 w-40 border-neutral-600"
                        />
                      </FormControl>

                      <FormMessage data-name="credits" />
                    </FormItem>
                  )}
                />
              </div>

              <Button
                type="submit"
                className="py-3 px-5 font-medium tracking-widest h-11 mt-10"
                isLoading={isLoading}
              >
                Save Changes
              </Button>
            </form>
          </Form>

          <Button
            className="py-3 px-5 font-medium tracking-widest h-11 border-2 mt-2"
            onClick={() => setOpen(false)}
            variant={'outline'}
          >
            Cancel
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  )
}
