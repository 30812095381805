import { useEffect } from 'react'

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { useAppSelector } from '@/hooks/redux'
import { useRedirectBasedOnPermissions } from '@/hooks/useRedirectBasedOnPermissions'
import { selectItsDefaultState } from '@/store/api/Users/Users.selector'

import { UsersTable } from './UsersTable'

export const Users = () => {
  const { hasHandleUsers } = useRedirectBasedOnPermissions()
  const itsDefaultState = useAppSelector(selectItsDefaultState)

  useEffect(() => {
    if (!itsDefaultState) hasHandleUsers()
  }, [itsDefaultState])

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl text-textAccent font-semibold">
          Users
        </CardTitle>
      </CardHeader>
      <CardContent>
        <UsersTable />
      </CardContent>
    </Card>
  )
}
