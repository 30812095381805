import type {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { isEmpty } from 'ramda'
import * as React from 'react'

import { EmptyState } from '@/components/EmptyState/EmptyState'
import { Loader } from '@/components/Loader/Loader'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { useListPaginatedOfTopicsQuery } from '@/store/api/Topics/Topics.query'

import { ModifyTopicPopUp } from './ModifyTopicPopUp'
import { TopicsPopUp } from './TopicsPopUp'

export function TopicsTable() {
  const { data, isLoading } = useListPaginatedOfTopicsQuery({})
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  )
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})
  const [globalFilter, setGlobalFilter] = React.useState('')

  const columns: ColumnDef<any>[] = [
    {
      accessorKey: 'name',
      header: 'Name',
      cell: ({ row }) => (
        <div className="capitalize text-neutral-800 text-base font-normal">
          {row.getValue('name') || <p className="text-red-600">Nameless</p>}
        </div>
      ),
    },
    {
      accessorKey: 'isActive',
      header: 'Status',
      cell: ({ row }) => (
        <div className="text-neutral-800 text-base font-normal">
          {row.getValue('isActive') ? (
            <p className="text-green-600">Active</p>
          ) : (
            <p className="text-red-600">Inactive</p>
          )}
        </div>
      ),
    },
    {
      accessorKey: 'enterprise',
      header: 'Enterprise',
      cell: ({ row }) => (
        <div className="text-neutral-800 text-base font-normal">
          {row.getValue('enterprise') || (
            <p className="text-red-600">Without enterprise</p>
          )}
        </div>
      ),
    },
    {
      accessorKey: 'prompt',
      header: () => <div className="text-center">Prompt & Description</div>,
      cell: ({ row }) => (
        <div className="flex justify-center">
          <TopicsPopUp
            translations={data?.info.list[row.index].translations}
            description={data?.info.list[row.index].description}
            prompt={data?.info.list[row.index].prompt}
          />
          <ModifyTopicPopUp
            id={data?.info.list[row.index]._id}
            name={data?.info.list[row.index].name}
            enterprise={data?.info.list[row.index].enterprise}
            translations={data?.info.list[row.index].translations}
          />
        </div>
      ),
    },
  ]

  const table = useReactTable({
    data: data?.info.list ?? [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      globalFilter,
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  if (isLoading) {
    return (
      <div className="flex h-96 items-center">
        <div className="flex justify-center w-full">
          <Loader />
        </div>
      </div>
    )
  }

  if (isEmpty(data)) {
    return <EmptyState text="We will show here the topics when there are any" />
  }

  return (
    <div className="w-full">
      <div className="flex items-center py-4 h-12 mb-2">
        <Input
          placeholder="Filter by any element..."
          value={globalFilter}
          onChange={(event) => {
            const value = event.target.value
            setGlobalFilter(value)
          }}
          className="w-96 h-10"
        />
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  className="bg-backgroundHighlight hover:bg-muted/50 "
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="border-r">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="text-center">
                  <EmptyState text="There seem to be results for your search" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}
