import { Eye } from 'iconsax-react'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export const PDFPreferencesPopUp = ({
  logo,
  displayName,
  palletBgColor,
  palletHlColor,
  palletAcColor,
}: {
  logo: string
  displayName: string
  palletBgColor: string
  palletHlColor: string
  palletAcColor: string
}) => {
  return (
    <Dialog>
      <DialogTrigger
        disabled={(palletBgColor && palletAcColor && palletHlColor) === ''}
      >
        <Button
          disabled={(palletBgColor && palletAcColor && palletHlColor) === ''}
          className="w-10 h-10 bg-gray-200 rounded-[100px] justify-center items-center gap-1 mr-2"
          size={'icon'}
        >
          <Eye
            width={22}
            height={22}
            variant="Outline"
            color={weCheckPalette.icon.disableIcon}
          />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>PDF Preferences</DialogTitle>
          <DialogDescription>
            Here are the preferences that the user used to make the report.
          </DialogDescription>
        </DialogHeader>
        <DialogDescription>
          <div className="flex flex-col gap-1">
            <div className=" flex flex-col gap-3 text-neutral-800 text-sm font-medium">
              <p>Logo</p>
              <div className="border-neutral-200 border-[1.5px] rounded-lg flex flex-row gap-3 p-2">
                <div>
                  <img src={logo} alt={logo} height={36} width={64} />
                </div>
                <div>
                  <p>Url:</p>
                  <p>{logo}</p>
                </div>
              </div>
              <p>Labeling Name</p>
              <div className="border-neutral-200 border-[1.5px] rounded-lg flex flex-row gap-3 p-2">
                <p>{displayName}</p>
              </div>
              <p>PDF Colors</p>

              <div className="border-neutral-200 border-[1.5px] rounded-lg flex flex-col gap-3 p-2">
                <div className="flex flex-row items-center">
                  <div
                    className={` border-[0.5px] border-neutral-800 rounded-full h-6 w-6 mr-3`}
                    style={{ backgroundColor: palletBgColor }}
                  ></div>
                  <div>
                    <p>
                      Background Color:
                      <p>{palletBgColor}</p>
                    </p>
                  </div>
                </div>
                <div className="flex flex-row  items-center">
                  <div
                    className={`border-[0.5px] border-neutral-800 rounded-full h-6 w-6 mr-3`}
                    style={{ backgroundColor: palletHlColor }}
                  ></div>
                  <div>
                    <p>Highlight Color: </p>
                    <p>{palletHlColor}</p>
                  </div>
                </div>
                <div className="flex flex-row items-center">
                  <div
                    className={`border-[0.5px] border-neutral-800 rounded-full h-6 w-6 mr-3`}
                    style={{ backgroundColor: palletAcColor }}
                  ></div>
                  <div>
                    <p>Accent Color: </p>
                    <p>{palletAcColor}</p>
                  </div>
                </div>
              </div>

              <p>Example</p>
              <div className="flex flex-col gap-3 text-neutral-800 text-sm font-medium">
                <div
                  className={`p-3`}
                  style={{ backgroundColor: palletBgColor }}
                >
                  <div
                    className={`p-3`}
                    style={{ backgroundColor: palletHlColor }}
                  >
                    <p style={{ color: palletAcColor }}>Accent Color</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  )
}
