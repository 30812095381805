import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { BackHeader } from '@/components/BackHeader/BackHeader'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Textarea } from '@/components/ui/text-area'
import { AppRoutes } from '@/constants/appRoutes'
import {
  useCreateNewPostMutation,
  useCreateNewWebSearchPostMutation,
} from '@/store/api/Subjects/Subjects.query'
import type {
  createNewPostRequest,
  createNewWebSearchPostRequest,
} from '@/store/api/Subjects/Subjects.types'
import type { createNewPostSchemaType } from '@/utils/schemas'
import { createNewPostSchema } from '@/utils/schemas'

import 'react-datepicker/dist/react-datepicker.css'
import { format } from 'date-fns'

export const NewPost = () => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const [selectedType, setSelectedType] = useState<string>()
  const [selectedSentiment, setSelectedSentiment] = useState<string>()
  const [createNewPost, { isLoading }] = useCreateNewPostMutation()
  const [createNewWebSearchPost, { isLoading: isLoadingCreateWebSearchPost }] =
    useCreateNewWebSearchPostMutation()
  const [startDate, setStartDate] = useState(new Date())

  const filterEmptyFields = (obj: any) => {
    const filteredObj: any = {}
    Object.keys(obj).forEach((key) => {
      if (
        (obj[key] !== '' && obj[key] !== null) ||
        ['id', 'subjectId'].includes(key)
      ) {
        filteredObj[key] = obj[key]
      }
    })
    return filteredObj
  }

  const handleTypeChange = (value: string) => {
    setSelectedType(value as string)
  }
  const handleSentimentChange = (value: string) => {
    setSelectedSentiment(value as string)
  }

  const handleCreateNewPost = async (data: any) => {
    const formattedDate = format(startDate, 'MM-dd-yyyy').toString()
    try {
      const requestInfo: createNewPostRequest = {
        content: data.content,
        link: data.link,
        mediaUrl: data.mediaUrl,
        videoLink: data.videoLink,
        type: selectedType,
        sentimentFlag: selectedSentiment,
        flags: data.flags
          ? data.flags.split(',').map((flag: string) => flag.trim())
          : [],
        flaggedText: data.flaggedText,
        subjectId: state.subjectId,
        id: state.id,
        flagReason: data.flagReason,
        sentimentReason: data.sentimentReason,
        date: formattedDate,
      }
      const filteredRequestInfo = filterEmptyFields(requestInfo)
      await createNewPost(filteredRequestInfo).unwrap()
      toast.success('Post created successfully.')
      navigate(AppRoutes.socialMedia, {
        state: state.subjectId,
      })
    } catch (e) {
      toast.error('Error creating new Post.')
      console.error(e)
    }
  }

  const handleCreateNewWebSearchPost = async (data: any) => {
    try {
      const requestInfo: createNewWebSearchPostRequest = {
        content: data.content,
        link: data.link,
        mediaUrl: data.mediaUrl,
        videoLink: data.videoLink,
        sentimentFlag: selectedSentiment,
        flags: data.flags
          ? data.flags.split(',').map((flag: string) => flag.trim())
          : [],
        flaggedText: data.flaggedText,
        subjectId: state.subjectId,
        flagReason: data.flagReason,
        sentimentReason: data.sentimentReason,
      }
      const filteredRequestInfo = filterEmptyFields(requestInfo)
      await createNewWebSearchPost(filteredRequestInfo).unwrap()
      toast.success('Web Search Post created successfully.')
      navigate(AppRoutes.socialMedia, {
        state: state.subjectId,
      })
    } catch (e) {
      toast.error('Error creating new Web Search Post.')
      console.error(e)
    }
  }

  const form = useForm<createNewPostSchemaType>({
    resolver: zodResolver(createNewPostSchema),
    defaultValues: {
      content: '',
      link: '',
      mediaUrl: '',
      videoLink: '',
      flags: '',
      flaggedText: '',
      sentimentReason: '',
      flagReason: '',
    },
    mode: 'onChange',
  })

  const { handleSubmit, control } = form

  const onSubmit = (data: any) => {
    handleCreateNewPost(data)
  }

  const onSubmitWebSearch = (data: any) => {
    handleCreateNewWebSearchPost(data)
  }
  return (
    <div>
      <Form {...form}>
        <form
          onSubmit={
            state.isWebSearch
              ? handleSubmit(onSubmitWebSearch)
              : handleSubmit(onSubmit)
          }
        >
          <div className="flex flex-row items-center">
            <BackHeader
              backText=""
              onPressBack={() =>
                navigate(AppRoutes.socialMedia, {
                  state: state.subjectId,
                })
              }
            />
          </div>
          <Card className="mt-8">
            <div className="p-6">
              <div className="flex justify-between mb-1 lg:flex-row flex-col-reverse gap-4">
                <p className="text-neutral-800 text-xl font-medium">
                  {state.isWebSearch
                    ? 'Create New Web Search Post'
                    : 'Create New Post'}
                </p>
              </div>
              <div className="lg:mb-8 mb-3">
                <p className="text-neutral-500 text-base font-normal">
                  Enter the data of the new post you want to create.
                </p>
              </div>
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-3">
                  <FormField
                    control={control}
                    name="content"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Content</FormLabel>
                        <FormControl>
                          <Textarea {...field} placeholder="Content" />
                        </FormControl>
                        <FormMessage data-name="Content" />
                      </FormItem>
                    )}
                  />
                  <FormLabel>Date*</FormLabel>
                  <div className="flex items-center">
                    <DatePicker
                      className="text-start h-8 border rounded-md text-sm px-4 py-4"
                      selected={startDate}
                      onChange={(date: any) => setStartDate(date)}
                    />
                  </div>
                  {!state.isWebSearch && (
                    <FormItem className="w-full ">
                      <FormLabel>Type*</FormLabel>
                      <Select
                        onValueChange={handleTypeChange}
                        value={selectedType}
                      >
                        <SelectTrigger className="w-full h-10">
                          <SelectValue placeholder="Select product type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>Types</SelectLabel>
                            <SelectItem value={'Like'}>Like</SelectItem>
                            <SelectItem value={'Post'}>Post</SelectItem>
                            <SelectItem value={'Repost'}>Repost</SelectItem>
                            <SelectItem value={'Reply'}>Reply</SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}

                  <FormField
                    control={control}
                    name="link"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6">
                        <FormLabel>Link*</FormLabel>
                        <FormControl>
                          <Textarea {...field} placeholder="Link" />
                        </FormControl>
                        <FormMessage data-name="link" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="mediaUrl"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6">
                        <FormLabel>Media Url</FormLabel>
                        <FormControl>
                          <Textarea {...field} placeholder="Media Url" />
                        </FormControl>
                        <FormMessage data-name="mediaUrl" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="videoLink"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6 ">
                        <FormLabel>Video Link</FormLabel>
                        <FormControl>
                          <Textarea {...field} placeholder="Video Link" />
                        </FormControl>
                        <FormMessage data-name="videoLink" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="flags"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Flags</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Flags" />
                        </FormControl>
                        <FormDescription>
                          Enter the flags as a comma-separated list (e.g.
                          "flag1, flag2, flag3").
                          <Link
                            className="ml-1 font-bold text-sky-700"
                            to={
                              'https://docs.google.com/spreadsheets/d/1jy1s1wWoEAL5eJxE3TEIfOSCJNp8KiI68n6d2qWTLH0/edit?gid=0#gid=0'
                            }
                          >
                            Click here to see the list of available topics.
                          </Link>
                        </FormDescription>
                        <FormMessage data-name="flags" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="flaggedText"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Flagged Text</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Flagged Text" />
                        </FormControl>
                        <FormMessage data-name="flaggedText" />
                      </FormItem>
                    )}
                  />
                  <FormItem className="w-full ">
                    <FormLabel>Sentiment Flag*</FormLabel>
                    <Select
                      onValueChange={handleSentimentChange}
                      value={selectedSentiment}
                    >
                      <SelectTrigger className="w-full h-10">
                        <SelectValue placeholder="Select product type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>Sentiment Flag</SelectLabel>
                          <SelectItem value={'Positive'}>Positive</SelectItem>
                          <SelectItem value={'Negative'}>Negative</SelectItem>
                          <SelectItem value={'Neutral'}>Neutral</SelectItem>

                          <SelectItem value={'Flagged'}>Flagged</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </FormItem>
                  <FormField
                    control={control}
                    name="sentimentReason"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Sentiment Reason</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Sentiment Reason" />
                        </FormControl>
                        <FormMessage data-name="sentimentReason" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="flagReason"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Flag Reason</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Flag Reason" />
                        </FormControl>
                        <FormMessage data-name="flagReason" />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex gap-6">
                  <Button
                    isLoading={isLoading || isLoadingCreateWebSearchPost}
                    type="submit"
                    disabled={
                      !form.formState.isValid ||
                      !selectedSentiment ||
                      (!state.isWebSearch && !selectedType)
                    }
                    className="h-12 px-5 py-3 bg-neutral-800 rounded-lg"
                  >
                    <p className="text-neutral-50 text-base font-medium tracking-wide">
                      Create Post
                    </p>
                  </Button>
                  <Button
                    className="w-24 h-12 px-5 py-3 bg-zinc-100 rounded-lg"
                    onClick={() =>
                      navigate(AppRoutes.socialMedia, {
                        state: state.subjectId,
                      })
                    }
                  >
                    <p className="text-neutral-800 text-base font-medium tracking-wide">
                      Cancel
                    </p>
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </form>
      </Form>
    </div>
  )
}
