import { zodResolver } from '@hookform/resolvers/zod'
import { Edit } from 'iconsax-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useChangeReportPdfConfigurationMutation } from '@/store/api/Admin/Admin.query'
import type { ReportItem } from '@/store/api/Admin/Admin.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import type { changeReportConfigSchemaType } from '@/utils/schemas'
import { changeReportConfigSchema } from '@/utils/schemas'

export const EditPdfConfigPopUp = ({
  subjectId,
  currentReportPdfConfiguration,
}: {
  subjectId: string
  currentReportPdfConfiguration: ReportItem
}) => {
  const [selectedLang, setSelectedLang] = useState<string>(
    currentReportPdfConfiguration.pdfLanguage,
  )

  const handleProductChange = (value: string) => {
    setSelectedLang(value)
  }

  const [changeReportConfig, { isLoading }] =
    useChangeReportPdfConfigurationMutation()

  const handleChangeReportConfig = async (data: any) => {
    try {
      await changeReportConfig({
        subjectId: subjectId,
        pdfLanguage: selectedLang,
        ...data,
      }).unwrap()
      toast.success('Changes saves successfully')
    } catch (error) {
      toast.error('Error saving changes.')
      catchWithSentry(error)
    }
  }

  const form = useForm<changeReportConfigSchemaType>({
    resolver: zodResolver(changeReportConfigSchema),
    defaultValues: {
      pdfCoreFindings: currentReportPdfConfiguration.pdfCoreFindings,
      pdfSocialMediaAccounts:
        currentReportPdfConfiguration.pdfSocialMediaAccounts,
      pdfSentimentAnalysis: currentReportPdfConfiguration.pdfSentimentAnalysis,
      pdfFlaggedBehaviors: currentReportPdfConfiguration.pdfFlaggedBehaviors,
      pdfPositivePosts: currentReportPdfConfiguration.pdfPositivePosts,
      pdfNegativePosts: currentReportPdfConfiguration.pdfNegativePosts,
      pdfNeutralPosts: currentReportPdfConfiguration.pdfNeutralPosts,
      pdfWebSearchPosts: currentReportPdfConfiguration.pdfWebSearchPosts,
      pdfFocusedTopics: currentReportPdfConfiguration.pdfFocusedTopics,
    },
    mode: 'onChange',
  })

  const { handleSubmit, control } = form

  const switchFields = [
    { name: 'pdfCoreFindings', label: 'Core Findings' },
    { name: 'pdfSocialMediaAccounts', label: 'Social Media Accounts' },
    { name: 'pdfSentimentAnalysis', label: 'Sentiment Analysis' },
    { name: 'pdfFlaggedBehaviors', label: 'Flagged Behaviors' },
    { name: 'pdfPositivePosts', label: 'Positive Posts' },
    { name: 'pdfNegativePosts', label: 'Negative Posts' },
    { name: 'pdfNeutralPosts', label: 'Neutral Posts' },
    { name: 'pdfWebSearchPosts', label: 'Web Search Posts' },
    { name: 'pdfFocusedTopics', label: 'Focused Topics' },
  ]

  return (
    <Dialog>
      <DialogTrigger>
        <Button
          className="w-10 h-10 bg-gray-200 rounded-full justify-center items-center mr-2"
          size={'icon'}
        >
          <Edit
            size="24"
            color={weCheckPalette.icon.disableIcon}
            variant="Outline"
          />
        </Button>
      </DialogTrigger>
      <DialogContent className="flex flex-col gap-6">
        <DialogHeader>
          <DialogTitle>Change Report PDF Configuration</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            className="flex flex-col gap-6"
            onSubmit={handleSubmit(handleChangeReportConfig)}
          >
            <div className="flex flex-col gap-3">
              {switchFields.map((switchField) => (
                <FormField
                  key={switchField.name}
                  control={control}
                  name={switchField.name as keyof changeReportConfigSchemaType}
                  render={({ field }) => (
                    <FormItem className="w-full flex justify-between items-center">
                      <FormLabel>{switchField.label}?</FormLabel>
                      <div className="flex w-20 justify-between">
                        <FormControl>
                          <Switch
                            required={false}
                            className="w-11 h-6"
                            checked={
                              typeof field.value === 'boolean'
                                ? field.value
                                : field.value === 'true'
                            }
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <p className="text-neutral-800 font-medium">
                          {field.value ? (
                            <span className="text-green-600 ">Yes</span>
                          ) : (
                            <span className="text-red-600">No</span>
                          )}
                        </p>
                      </div>
                      <FormMessage data-name={field.name} />
                    </FormItem>
                  )}
                />
              ))}

              <Select onValueChange={handleProductChange} value={selectedLang}>
                <FormLabel>Language</FormLabel>
                <SelectTrigger className="w-full h-8 border-neutral-600">
                  <SelectValue
                    placeholder={selectedLang || 'Select Language'}
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="en">English</SelectItem>
                  <SelectItem value="es">Spanish</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <Button type="submit" isLoading={isLoading}>
              <p>Save Changes</p>
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
