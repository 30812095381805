import {
  ProfileCircle,
  ShieldTick,
  Menu,
  ArrowLeft2,
  ArrowRight2,
  Buildings2,
  DocumentCopy,
  ToggleOnCircle,
  ToggleOffCircle,
  UserEdit,
} from 'iconsax-react'
import { useEffect, useState } from 'react'
import { Sidebar } from 'react-pro-sidebar'
import { Outlet, useLocation } from 'react-router-dom'

import { Loader } from '@/components/Loader/Loader'
import { Button } from '@/components/ui/button'
import env from '@/env/env'
import { useAppDispatch, useAppSelector } from '@/hooks/redux'
import { useCheckSession } from '@/hooks/useCheckSession'
import { useDisableBasedOnPermissions } from '@/hooks/useDisableBasedOnPermissions'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import { selectIsNavBarCollapsed } from '@/store/api/ui/ui.selector'
import { collapseNavBar, expandNavBar } from '@/store/api/ui/ui.slice'
import { useUserInfoQuery } from '@/store/api/Users/Users.query'
import {
  selectIsPending,
  selectUserFirstLetter,
  selectUserFullname,
} from '@/store/api/Users/Users.selector'
import { setUserInfo } from '@/store/api/Users/Users.slice'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import SidebarLogo from '../../assets/sidebar/sidebarLogo.svg?react'

import { SidebarItem } from './components/sidebarItem/SidebarItem'
import { SidebarLogout } from './components/sidebarLogout/sidebarLogout'
import { SidebarUser } from './components/sidebarUser/sidebarUser'
import { WecheckEnviromentDistinction } from './components/WecheckEnviromentDistinction/WecheckEnviromentDistinction'

export const Dashboard = () => {
  const location = useLocation().pathname
  const dispatch = useAppDispatch()
  const permissions = useDisableBasedOnPermissions()
  const [broken, setBroken] = useState(false)
  const [toggled, setToggled] = useState(false)
  const collapsed = useAppSelector(selectIsNavBarCollapsed)
  const isMobile = useMobileMediaQuery()
  useCheckSession()
  const enviroment = env.NODE_ENV

  const {
    isLoading: isLoadingUserData,
    data: userData,
    isPending,
    userFullname,
    userFirstLetter,
  } = useUserInfoQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      isPending: selectIsPending(data),
      userFullname: selectUserFullname(data),
      userFirstLetter: selectUserFirstLetter(data),
      data,
    }),
  })

  useEffect(() => {
    if (userData) {
      dispatch(setUserInfo(userData))
    }
  }, [userData])

  if (isLoadingUserData || !userData) {
    return (
      <div className="flex h-screen items-center">
        <div className="flex justify-center w-full">
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <div className="h-screen">
      <div className="flex lg:h-screen w-full">
        <Sidebar
          toggled={toggled}
          className="flex lg:py-5 2xl:py-10"
          onBreakPoint={setBroken}
          width={broken ? undefined : '340'}
          onBackdropClick={() => setToggled(false)}
          breakPoint="md"
          collapsed={collapsed}
          backgroundColor={weCheckPalette.background}
        >
          <div
            className={`flex flex-col mx-4 h-full ${
              collapsed ? 'items-center' : ''
            }`}
          >
            {isMobile && (
              <div className="my-4">
                <SidebarUser
                  mail={userData.email}
                  name={userFullname}
                  userInitials={userFirstLetter}
                  isPending={isPending}
                  collapsed={collapsed}
                />
              </div>
            )}

            <WecheckEnviromentDistinction
              enviroment={enviroment}
              collapsed={collapsed}
            />

            <div className="flex flex-col flex-1">
              <SidebarItem
                name="Enterprises"
                isActive={location === '/dashboard/enterprises'}
                icon={<Buildings2 color={'#252525'} />}
                iconDisabled={<Buildings2 color={weCheckPalette.textAccent} />}
                iconActive={<Buildings2 color="#d9e3f0" variant="Bold" />}
                linkUrl="enterprises"
                disabled={!permissions.handleEnterprises}
                collapsed={collapsed}
              />
              <SidebarItem
                name="Users"
                isActive={location === '/dashboard/users'}
                icon={<ProfileCircle color={'#252525'} />}
                iconDisabled={
                  <ProfileCircle color={weCheckPalette.textAccent} />
                }
                iconActive={<ProfileCircle color="#d9e3f0" variant="Bold" />}
                linkUrl="users"
                disabled={!permissions.handleUsers}
                collapsed={collapsed}
              />
              <SidebarItem
                name="Reports"
                isActive={location === '/dashboard/reports'}
                icon={<ShieldTick color={'#252525'} />}
                iconDisabled={<ShieldTick color={weCheckPalette.textAccent} />}
                iconActive={<ShieldTick color="#d9e3f0" variant="Bold" />}
                linkUrl="reports"
                disabled={!permissions.handleReports}
                collapsed={collapsed}
              />
              <SidebarItem
                name="BatchUpload"
                isActive={location === '/dashboard/batchUpload'}
                icon={<DocumentCopy color={'#252525'} />}
                iconDisabled={
                  <DocumentCopy color={weCheckPalette.textAccent} />
                }
                iconActive={<DocumentCopy color="#d9e3f0" variant="Bold" />}
                linkUrl="batchUpload"
                disabled={!permissions.handleReports}
                collapsed={collapsed}
              />
              <SidebarItem
                name="Topics"
                isActive={
                  location === '/dashboard/topics' ||
                  location === '/dashboard/newTopic'
                }
                icon={<ToggleOffCircle color={'#252525'} />}
                iconDisabled={
                  <ToggleOffCircle color={weCheckPalette.textAccent} />
                }
                iconActive={<ToggleOnCircle color="#d9e3f0" variant="Bold" />}
                linkUrl="topics"
                disabled={!permissions.handleEnterprises}
                collapsed={collapsed}
              />
              <SidebarItem
                name="Social Media"
                isActive={
                  location === '/dashboard/socialMedia' ||
                  location === '/dashboard/newSocialMedia' ||
                  location === '/dashboard/newPost'
                }
                icon={<UserEdit color={'#252525'} />}
                iconDisabled={<UserEdit color={weCheckPalette.textAccent} />}
                iconActive={<UserEdit color="#d9e3f0" variant="Bulk" />}
                linkUrl="socialMedia"
                disabled={!permissions.handleReports}
                collapsed={collapsed}
              />
            </div>
            <SidebarLogout collapsed={collapsed} />
            {!isMobile && (
              <SidebarUser
                mail={userData.email}
                name={userFullname}
                userInitials={userFirstLetter}
                isPending={isPending}
                collapsed={collapsed}
              />
            )}
            <div className="flex items-center lg:mt-7 lg:mb-0 mt-0 mb-6">
              <Button
                asChild
                size={'icon'}
                className="bg-background"
                variant={'outline'}
                onClick={() => {
                  if (collapsed) {
                    dispatch(expandNavBar())
                  } else {
                    dispatch(collapseNavBar())
                  }
                }}
              >
                <div>
                  {collapsed && <ArrowRight2 width={20} height={20} />}
                  {!collapsed && <ArrowLeft2 width={20} height={20} />}
                </div>
              </Button>
              {!collapsed && (
                <p className="ml-2 text-base tracking-wide">Collapse</p>
              )}
            </div>
          </div>
        </Sidebar>
        <main className="w-full h-screen lg:h-auto bg-gradient-to-r from-appGradient-start to-appGradient-end">
          {broken && (
            <div className="p-4 flex justify-between bg-gradient-to-r from-appGradient-start to-appGradient-end">
              <Button
                asChild
                size={'icon'}
                className="bg-background"
                variant={'outline'}
                onClick={() => setToggled(!toggled)}
              >
                <div>
                  <Menu width={20} height={20} variant="Bold" />
                </div>
              </Button>

              <SidebarLogo viewBox="0 0 220 33" width={350} />
            </div>
          )}
          <div className="lg:px-8 lg:py-8 px-4 py-5  w-full bg-gradient-to-r from-appGradient-start to-appGradient-end max-h-full overflow-auto lg:overscroll-none ">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  )
}
