import type { SidebarUserTypes } from './sidebarUser.types'

export const SidebarUser = ({
  collapsed,
  userInitials,
  name,
  mail,
  isPending,
}: SidebarUserTypes) => {
  return (
    <div className="flex flex-row justify-between pt-10">
      <div
        className={`flex justify-center items-center bg-gradient-to-r from-appGradient-start to-appGradient-end p-2 lg:p-0 rounded-full w-8 h-8 lg:w-12 lg:h-12 ${
          collapsed ? '' : 'mr-3 lg:mr-3'
        }`}
      >
        <p className="lg:text-3xl text-l  lg:h-9 tracking-tighter">
          {userInitials}
        </p>
      </div>
      {!collapsed && (
        <>
          <div className="flex flex-col flex-1 justify-center">
            <div>
              <p className="text-base font-medium tracking-wider max-w-20 lg:max-w-none truncate">
                {name}
              </p>
            </div>
            <div>
              <p className="text-textNormal max-w-20 lg:max-w-none truncate">
                {mail}
              </p>
            </div>
          </div>
          {isPending && (
            <div className="bg-chipBackground py-1 px-2 rounded h-7 ml-2 justify-center items-center">
              <p className="text-xs tracking-wider font-medium">Pending</p>
            </div>
          )}
        </>
      )}
    </div>
  )
}
