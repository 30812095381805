import React from 'react'

import { PersonalInfoCard } from './PersonalInfoCard'
import { PersonalInfoPopUp } from './PersonalInfoPopUp'
import { subjectInfo } from '@/store/api/Subjects/Subjects.types'

interface DisplayPersonInfoTypes {
  data: subjectInfo
}

export const DisplayPersonalInfo = ({ data }: DisplayPersonInfoTypes) => {
  const topics = data.input.topics.join(', ')
  return (
    <div className="flex gap-10">
      <div className="flex">
        <div className="w-[250px] h-[250px]">
          <img
            alt={data.personData.name || 'Profile pic'}
            src={data.personData.profilePicUrl}
            className="w-full h-full rounded-lg"
          />
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex gap-4">
          <p className="text-neutral-800 text-4xl font-medium tracking-wide">
            {data.personData.name}
          </p>
          <PersonalInfoPopUp
            pinterestUrl={data.personData.pinterestUrl}
            redditUrl={data.personData.redditUrl}
            tiktokUrl={data.personData.tiktokUrl}
            linkedInUrl={data.personData.linkedInUrl}
            instagramUrl={data.personData.instagramUrl}
            facebookUrl={data.personData.facebookUrl}
            twitterUrl={data.personData.twitterUrl}
            profilePicUrl={data.personData.profilePicUrl}
          />
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex gap-4">
            <PersonalInfoCard
              keyName={'Location'}
              value={`${data.personData.city || '-'}, ${
                data.personData.state || '-'
              }, ${data.personData.country || '-'}`}
            />

            <PersonalInfoCard
              keyName={'Address'}
              value={data.personData.address || '-'}
            />
          </div>
          <div className="flex gap-4">
            <PersonalInfoCard
              keyName={'Age or Date Of Birth'}
              value={data.personData.ageOrDateOfBirth || '-'}
            />

            <PersonalInfoCard
              keyName={'Nickname'}
              value={data.personData.nickname || '-'}
            />
          </div>
          <div className="flex gap-4">
            <PersonalInfoCard
              keyName={'Email'}
              value={data.personData.email || '-'}
            />

            <PersonalInfoCard
              keyName={'Phone Number'}
              value={data.personData.phoneNumber || '-'}
            />
          </div>
          <div className="flex gap-4">
            <PersonalInfoCard
              keyName={'Highschool'}
              value={data.personData.highSchool || '-'}
            />

            <PersonalInfoCard
              keyName={'College'}
              value={data.personData.college || '-'}
            />
          </div>
          <div className="flex gap-4">
            <PersonalInfoCard
              keyName={'Company'}
              value={data.personData.company || '-'}
            />
            <PersonalInfoCard keyName={'Topics'} value={topics || '-'} />
          </div>
        </div>
      </div>
    </div>
  )
}
