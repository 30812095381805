import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@radix-ui/react-collapsible'
import { ArrowDown } from 'iconsax-react'
import React, { useState } from 'react'

import { Button } from '@/components/ui/button'
import type { Summary } from '@/store/api/Subjects/Subjects.types'

import { SummaryCard } from './SummaryCard'

interface DisplaySummaryTypes {
  summary: Summary
}

export const DisplaySummary = ({ summary }: DisplaySummaryTypes) => {
  const [isOpen, setIsOpen] = useState(false)

  const summaryFields = [
    { title: 'Executive Aim', text: summary.executiveAim },
    { title: 'Executive Summary', text: summary.executiveSummary },
    { title: 'Risk Indicators', text: summary.riskIndicators },
    { title: 'Sentiment Analysis', text: summary.sentimentAnalysis },
    { title: 'Red Flags', text: summary.redFlags },
    { title: 'Web News Findings', text: summary.webNewsFindings },

    { title: 'Digital Behavior', text: summary.digitalBehavior },
    { title: 'Digital Influence', text: summary.digitalInfluence },
    {
      title: 'Recommendations Insights',
      text: summary.recommendationsInsights,
    },
    {
      title: 'Recommendations Improvements',
      text: summary.recommendationsImprovements,
    },
    {
      title: 'Recommentations Conclusion',
      text: summary.recommentationsConclusion,
    },
    {
      title: 'Financial Misconduct (Only Adverse Media)',
      text: summary.financialMisconduct,
    },
    {
      title: 'Legal Dispute (Only Adverse Media)',
      text: summary.legalDispute,
    },
    {
      title: 'Criminal Records (Only Adverse Media)',
      text: summary.criminalRecords,
    },
    {
      title: 'Legal Judgments (Only Adverse Media)',
      text: summary.legalJudgments,
    },
    {
      title: 'Positive Mentions (Only Adverse Media)',
      text: summary.positiveMentions,
    },
    {
      title: 'Assessment (Only Adverse Media)',
      text: summary.assessment,
    },
    {
      title: 'Compliance Disclaimer (Only Adverse Media)',
      text: summary.complianceDisclaimer,
    },
    {
      title: 'Adverse Media Summary (Only Adverse Media)',
      text: summary.adverseMediaSummary,
    },
  ]

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="w-full space-y-2"
    >
      <CollapsibleTrigger asChild>
        <Button className="w-full p-2 h-10" variant="outline">
          <ArrowDown size="24" color="#555555" />
          <p className="text-neutral-800 text-md font-medium tracking-wide">
            View Summary
          </p>
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="flex flex-col gap-4">
          {summaryFields.map((field, index) => (
            <SummaryCard key={index} title={field.title} text={field.text} />
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}
