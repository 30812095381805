export interface setCustomPriceRequest {
  amount: number
  product: string
  creditType?: string
  id: string
}

export enum ETypeCredits {
  ANALYST_REPORT = 'ai_analyst_report',
  AI_REPORT = 'ai_report',
  ADVERSE_MEDIA_REPORT = 'adverse_media_report',
  ASSISTED_REPORT = 'assisted_report',
  FACIAL_MATCHING = 'facial_matching',
}

export interface addCreditsRequest {
  quantity: number
  type: ETypeCredits
  id: string
}
