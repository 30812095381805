import { SearchNormal1 } from 'iconsax-react'
import { useState } from 'react'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useDisableBasedOnPermissions } from '@/hooks/useDisableBasedOnPermissions'
import {
  useConfigurationQuery,
  useUsersRolAndStateMutation,
} from '@/store/api/Admin/Admin.query'
import type { UserItem } from '@/store/api/Admin/Admin.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export const RecentSearchUserItemSheet = ({ data }: { data: UserItem }) => {
  const permissions = useDisableBasedOnPermissions()

  const [selectedStatus, setSelectedStatus] = useState<string>(data.status)
  const [selectedRole, setSelectedRole] = useState<string>(data.role)
  const [usersRolAndState, { isLoading }] = useUsersRolAndStateMutation()
  const { data: configuration, isLoading: configurationisLoading } =
    useConfigurationQuery()

  const handleDrawer = () => {}

  const handleStatusChange = (value: string) => {
    setSelectedStatus(value as typeof selectedStatus)
  }

  const handleRoleChange = (value: string) => {
    setSelectedRole(value as typeof selectedRole)
  }

  const handleUpdateRolAndStatus = async () => {
    try {
      await usersRolAndState({
        id: data.id,
        status: selectedStatus,
        role: selectedRole,
      }).unwrap()
      toast.success('Changes saves successfully')
    } catch (error) {
      toast.error('Error saving changes.')
      catchWithSentry(error)
    }
  }

  const textColors: { [key: string]: string } = {
    active: 'text-green-600',
    pending: 'text-blue-600',
    blocked: 'text-red-600',
    pending_on_boarding: 'text-yellow-600',
    deleted: 'text-gray-500',
  }

  return (
    <Sheet>
      <SheetTrigger disabled={!permissions.modifyUsers}>
        <Button
          disabled={!permissions.modifyUsers}
          size={'icon'}
          className="bg-icon-fillBackground rounded-full mr-6"
          variant={'outline'}
          onClick={handleDrawer}
        >
          <div>
            <SearchNormal1
              width={22}
              height={22}
              variant="Outline"
              color={weCheckPalette.icon.disableIcon}
            />
          </div>
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle className="text-neutral-800 text-2xl font-medium tracking-wide">
            Actions
          </SheetTitle>
        </SheetHeader>
        <div className="mt-6">
          <SheetTitle className="text-neutral-800 text-xl font-medium tracking-tight">
            Select User Status:
          </SheetTitle>
        </div>
        <div className="flex flex-row gap-3 mt-4">
          <Select onValueChange={handleStatusChange} value={selectedStatus}>
            <SelectTrigger className="w-full mx-2">
              <SelectValue placeholder="Select user status" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Status</SelectLabel>
                {!configurationisLoading &&
                  configuration?.status.map((item: string) => (
                    <SelectItem className={textColors[item]} value={item}>
                      {item}
                    </SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className="mt-6">
          <SheetTitle className="text-neutral-800 text-xl font-medium tracking-tight">
            Select User Role:
          </SheetTitle>
        </div>
        <div className="flex flex-row gap-3 mt-4">
          <Select onValueChange={handleRoleChange} value={selectedRole}>
            <SelectTrigger className="w-full mx-2">
              <SelectValue placeholder="Select user role" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Staff Roles</SelectLabel>
                {!configurationisLoading &&
                  configuration?.staffRoles.map((item: string) => (
                    <SelectItem value={item}>{item}</SelectItem>
                  ))}
                <SelectLabel>Enterprise Roles</SelectLabel>
                {!configurationisLoading &&
                  configuration?.enterpriseRoles.map((item: string) => (
                    <SelectItem value={item}>{item}</SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className="mt-6">
          <Button
            onClick={handleUpdateRolAndStatus}
            disabled={isLoading}
            className="py-3 px-5 font-medium tracking-widest h-11 border-2 w-full"
          >
            {isLoading ? 'Updating...' : 'Update User'}
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  )
}
