import { format } from 'date-fns'
import { SearchNormal1 } from 'iconsax-react'
import React, { useState } from 'react'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
} from '@/components/ui/sheet'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useDisableBasedOnPermissions } from '@/hooks/useDisableBasedOnPermissions'
import {
  useReAnalyzeAndGeneratePdfMutation,
  useRegenerateReportMutation,
  useRegenerateWithoutIAMutation,
  useRegenerateWithSummaryMutation,
  useUpdateReportValueMutation,
  useUploadPdfMutation,
} from '@/store/api/Admin/Admin.query'
import type { uploadPdfRequest } from '@/store/api/Admin/Admin.types'
import { EReportType } from '@/store/api/Admin/Admin.types'
import { getFileSize } from '@/utils/getFileSize'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { ConfirmActionPopUp } from './components/ConfirmActionPopUp'

interface reportSheetTypes {
  id: string
  url: string
  completeReport: boolean
  subjectId?: string
  requesterName?: string
  requesterEmail?: string
  reportType: EReportType
}

export const ReportSheet = ({
  requesterName,
  requesterEmail,
  id,
  url,
  completeReport,
  subjectId,
  reportType,
}: reportSheetTypes) => {
  const [open, setOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [uploadPdf, { isLoading }] = useUploadPdfMutation()
  const [updateReportValue, { isLoading: isLoadingReportValue }] =
    useUpdateReportValueMutation()
  const [regenerateReport, { isLoading: isLoadingRegenerateReport }] =
    useRegenerateReportMutation()

  const [
    regenerateWithoutAIReport,
    { isLoading: isLoadingRegenerateReportWithoutAI },
  ] = useRegenerateWithoutIAMutation()

  const [regenerateWithSummary, { isLoading: isLoadingRegenerateWithSummary }] =
    useRegenerateWithSummaryMutation()

  const [
    reAnalyzeAndGeneratePdf,
    { isLoading: isLoadingReAnalyzeAndGeneratePdf },
  ] = useReAnalyzeAndGeneratePdfMutation()

  const [openRegenerateWithoutIaDialog, setOpenRegenerateWithoutIaDialog] =
    useState(false)
  const [openRegenerateSummaryDialog, setOpenRegenerateSummaryDialog] =
    useState(false)
  const [openRegenerateWithIaDialog, setOpenRegenerateWithIaDialog] =
    useState(false)
  const [
    openReAnalyzeAndGeneratePdfDialog,
    setOpenReAnalyzeAndGeneratePdfDialog,
  ] = useState(false)
  const [changeToComplateDialog, setChangeToComplateDialogDialog] =
    useState(false)

  const permissions = useDisableBasedOnPermissions()

  const handleUpdateReportValue = async () => {
    try {
      const updateReportValueRequest = {
        socialCheckId: id,
        status: completeReport ? 'PendingReview' : 'Complete',
      }
      await updateReportValue(updateReportValueRequest).unwrap()

      toast.success('Value updated.')
    } catch (e) {
      toast.error('Error updating value.')

      catchWithSentry(e)
    }
  }

  const handleFileUpload = (event: any) => {
    setSelectedFile(event.target.files[0])
  }

  const handleUpload = async () => {
    if (!selectedFile) {
      toast.error('Please select a file.')
      return
    }

    try {
      const formData = new FormData()
      formData.append('file', selectedFile)
      const requestInfo: uploadPdfRequest = {
        file: formData,
        socialCheckId: id,
      }
      await uploadPdf(requestInfo).unwrap()
      toast.success('Changes saved successfully.')
    } catch (e) {
      toast.error('Error saving changes.')
      console.error(e)
    }
  }

  const onPressDowload = () => {
    window.open(url, '_blank')
  }

  const handleRegenerateReport = async () => {
    try {
      await regenerateReport({ id: subjectId }).unwrap()
      setOpen(false)
      toast.success('Report regenerated successfully')
    } catch (e) {
      toast.error('error regenerating report')
      catchWithSentry(e)
      console.error(e)
    }
  }

  const handleRegenerateWithoutAIReport = async () => {
    try {
      await regenerateWithoutAIReport({ id: subjectId }).unwrap()
      setOpen(false)
      toast.success('Report regenerated without using AI !! ')
    } catch (e) {
      toast.error('error regenerating report')
      catchWithSentry(e)
      console.error(e)
    }
  }

  const handleRegenerateSummaryReport = async () => {
    try {
      await regenerateWithSummary({ id: subjectId }).unwrap()
      setOpen(false)
      toast.success('Report regenerated with new Summary !! ')
    } catch (e) {
      toast.error('error regenerating report')
      catchWithSentry(e)
      console.error(e)
    }
  }

  const handleReAnalyzeAndGeneratePdf = async () => {
    try {
      await reAnalyzeAndGeneratePdf({ id: subjectId }).unwrap()
      setOpen(false)
      toast.success(
        'Report regenerated with post re analized and new summary !! ',
      )
    } catch (e) {
      toast.error('error regenerating report')
      catchWithSentry(e)
      console.error(e)
    }
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger>
        <Button
          className="w-10 h-10 bg-gray-200 rounded-[100px] justify-center items-center gap-1 "
          size={'icon'}
        >
          <SearchNormal1
            width={22}
            height={22}
            variant="Outline"
            color={weCheckPalette.icon.disableIcon}
          />
        </Button>
      </SheetTrigger>
      <SheetContent className="flex flex-col gap-4 min-w-[60vw]">
        <SheetHeader className="flex flex-col border-b pb-2 border-neutral-400 ">
          <SheetTitle className="text-neutral-800 text-2xl font-medium tracking-wide">
            Actions
          </SheetTitle>
          <SheetDescription>
            <div className="flex gap-1">
              <p className="text-neutral-800 font-semibold tracking-wide text-[16px]">
                Request By:{' '}
              </p>
              <p className="text-neutral-800 text-base font-medium">
                {requesterName}
              </p>
            </div>
            <div className="flex gap-1">
              <p className="text-neutral-800 font-semibold tracking-wide text-[16px]">
                Email:{' '}
              </p>
              <p className="text-neutral-800 text-base font-medium">
                {requesterEmail}
              </p>
            </div>
            <div className="flex gap-1">
              <p className="text-neutral-800 font-semibold tracking-wide text-[16px]">
                Subject ID:{' '}
              </p>
              <p className="text-neutral-800 text-base font-medium">
                {subjectId}
              </p>
            </div>
          </SheetDescription>
        </SheetHeader>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-6 p-2">
            <div className="flex flex-col gap-2">
              <SheetTitle className="text-neutral-800 text-xl font-medium tracking-tight">
                Upload New PDF:
              </SheetTitle>
              <div className="flex gap-2">
                <Input
                  className="py-2.5 font-medium tracking-widest"
                  accept="image/*,.pdf"
                  type="file"
                  id="pdf"
                  onChange={handleFileUpload}
                />
                <Button
                  type="submit"
                  className="py-3 px-5 font-medium tracking-widest h-11 w-[25%] flex"
                  onClick={handleUpload}
                  isLoading={isLoading}
                  disabled={!selectedFile}
                >
                  Upload Selected PDF
                </Button>
              </div>
            </div>

            <div className="flex flex-col gap-2 w-full border-neutral-400 h-22">
              <p className="text-neutral-800 text-xl font-medium tracking-tight">
                File info:
              </p>
              {selectedFile ? (
                <div className="flex flex-col px-2 gap-2">
                  <div className="flex gap-2 h-6 justify-start items-center w-[50%]">
                    <p className="text-neutral-800 text-md font-medium tracking-wide">
                      File Name:
                    </p>
                    <p
                      className={
                        'text-neutral-500 text-sm font-medium tracking-tight'
                      }
                    >
                      {selectedFile.name}
                    </p>
                  </div>
                  <div className="flex gap-2 h-6 justify-start items-center w-[50%]">
                    <p className="text-neutral-800 text-md font-medium tracking-wide">
                      File Size:
                    </p>
                    <p
                      className={
                        'text-neutral-500 text-sm font-medium tracking-tight'
                      }
                    >
                      {getFileSize(selectedFile.size)}
                    </p>
                  </div>
                  <div className="flex gap-2 h-6 justify-start items-center w-[50%]">
                    <p className="text-neutral-800 text-md font-medium tracking-wide">
                      Last Modified:
                    </p>
                    <p
                      className={
                        'text-neutral-500 text-sm font-medium tracking-tight'
                      }
                    >
                      {format(
                        new Date(selectedFile.lastModified),
                        'MM/dd/yyyy HH:mm',
                      )}
                    </p>
                  </div>
                </div>
              ) : (
                <p className={'font-medium tracking-widest text-md'}>
                  No file Selected.
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-4 justify-between">
            <h1 className="font-medium text-xl">Change status and download:</h1>
            <div className="flex">
              <div className="flex items-center gap-2 w-[50%]">
                <SheetTitle className="text-neutral-800  tracking-tight">
                  Download original PDF:
                </SheetTitle>
                <Button
                  className="py-3 px-5 font-medium tracking-widest h-11 "
                  onClick={onPressDowload}
                >
                  Download
                </Button>
              </div>
              <div className="flex items-center gap-2 w-[50%]">
                <SheetTitle className="text-neutral-800  tracking-tight">
                  Change PDF status:
                </SheetTitle>

                <ConfirmActionPopUp
                  onClick={handleUpdateReportValue}
                  isLoading={isLoadingReportValue}
                  TriggerText={
                    completeReport ? 'Set as pending ' : 'Set as complete '
                  }
                  Title={
                    'Are you sure you want to change the status of this report?'
                  }
                  Description={
                    'Please note that changing the status of this report will send a notification to the user who generated it.'
                  }
                  ConfirmText={
                    completeReport
                      ? 'Set as pending report'
                      : 'Set as complete report'
                  }
                  open={changeToComplateDialog}
                  setOpen={setChangeToComplateDialogDialog}
                />
              </div>
            </div>
          </div>
          {permissions && (
            <div>
              <h1 className="font-medium text-xl my-4">
                Regenerate report flows:
              </h1>
              <div className="flex">
                <div className="flex items-center gap-2 w-[50%]">
                  {reportType !== EReportType.AdverseMedia && (
                    <>
                      <SheetTitle className="text-neutral-800   tracking-tight">
                        Reset Report:
                      </SheetTitle>
                      <ConfirmActionPopUp
                        onClick={handleRegenerateReport}
                        open={openRegenerateWithIaDialog}
                        setOpen={setOpenRegenerateWithIaDialog}
                        isLoading={isLoadingRegenerateReport}
                        TriggerText={'⚠ Reset Report ⚠'}
                        Title={'Are you sure you want to reset the report?'}
                        Description={
                          'Please note that regenerating the report will also incur additional expenses. ALL CHANGES over the report will be lost'
                        }
                        ConfirmText={'Regenerate'}
                      />
                    </>
                  )}
                </div>
                <div className="flex items-center gap-2 w-[50%]">
                  <SheetTitle className="text-neutral-800  tracking-tight">
                    Regenerate Without AI PDF:
                  </SheetTitle>
                  <ConfirmActionPopUp
                    open={openRegenerateWithoutIaDialog}
                    setOpen={setOpenRegenerateWithoutIaDialog}
                    onClick={handleRegenerateWithoutAIReport}
                    isLoading={isLoadingRegenerateReportWithoutAI}
                    TriggerText={'Regenerate Without AI'}
                    Title={
                      'Are you sure you want to generate this report again?'
                    }
                    Description={'Regenerate this report will not use AI. '}
                    ConfirmText={'Regenerate'}
                  />
                </div>
              </div>
              <div className="flex items-center gap-2 w-[50%] mt-5">
                <SheetTitle className="text-neutral-800  tracking-tight">
                  Regenerate With Summary PDF:
                </SheetTitle>
                <ConfirmActionPopUp
                  open={openRegenerateSummaryDialog}
                  setOpen={setOpenRegenerateSummaryDialog}
                  onClick={handleRegenerateSummaryReport}
                  isLoading={isLoadingRegenerateWithSummary}
                  TriggerText={'Regenerate Summary'}
                  Title={
                    'You will regenerate the PDF Building a new Summary using the post our DB'
                  }
                  Description={
                    'Regenerate this will be use IA to generate the Summary again. '
                  }
                  ConfirmText={'Regenerate PFD with new Summary'}
                />
              </div>
              <div className="flex items-center gap-2 w-[50%] mt-5">
                <SheetTitle className="text-neutral-800  tracking-tight">
                  Re-Analyze With DB Data and Generate PDF:
                </SheetTitle>
                <ConfirmActionPopUp
                  open={openReAnalyzeAndGeneratePdfDialog}
                  setOpen={setOpenReAnalyzeAndGeneratePdfDialog}
                  onClick={handleReAnalyzeAndGeneratePdf}
                  isLoading={isLoadingReAnalyzeAndGeneratePdf}
                  TriggerText={'Re Analyze'}
                  Title={'You will Re analyze the DB Data and generate the PDF'}
                  Description={
                    'we will use our DB to: analyze the post, generate a summary and generate the PDF'
                  }
                  ConfirmText={'Re-Analyze With DB Data'}
                />
              </div>
            </div>
          )}
        </div>
      </SheetContent>
    </Sheet>
  )
}
