import { zodResolver } from '@hookform/resolvers/zod'
import { Edit } from 'iconsax-react'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Switch } from '@/components/ui/switch'
import { Textarea } from '@/components/ui/text-area'
import { useUpdateSocialMediaInfoMutation } from '@/store/api/Subjects/Subjects.query'
import type { updateSocialMediaInfoRequest } from '@/store/api/Subjects/Subjects.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import type { updateSocialMediaSchemaType } from '@/utils/schemas'
import { updateSocialMediaSchema } from '@/utils/schemas'

interface ModifySocialMediaPopUpTypes {
  id: string
  subjectId: string
  currentProfileImgUrl: string
  currentBio: string
  currentFollowers: number
  currentFollowing: number
  currentPostsCount: number
  currentProfileLink: string
  currentIsPrivate: boolean
}

export const ModifySocialMediaPopUp = ({
  id,
  subjectId,
  currentProfileImgUrl,
  currentBio,
  currentFollowers,
  currentFollowing,
  currentPostsCount,
  currentProfileLink,
  currentIsPrivate,
}: ModifySocialMediaPopUpTypes) => {
  const [updateSocialMediaInfo, { isLoading }] =
    useUpdateSocialMediaInfoMutation()

  const handleUpdateSocialMediaInfo = async (data: any) => {
    const followers = Number(data.followers as string)
    const following = Number(data.following as string)
    const postsCount = Number(data.postsCount as string)

    try {
      const requestInfo: updateSocialMediaInfoRequest = {
        id: id,
        subjectId: subjectId,
        profileImgUrl: data.profileImgUrl,
        profileLink: data.profileLink,
        followers: followers,
        following: following,
        postsCount: postsCount,
        bio: data.bio,
        isPrivate: data.isPrivate,
      }
      await updateSocialMediaInfo(requestInfo).unwrap()
      toast.success('Successfully Updated Social Network')
    } catch (e) {
      toast.error('Error Updating Social Network')
      console.error(e)
    }
  }

  const form = useForm<updateSocialMediaSchemaType>({
    resolver: zodResolver(updateSocialMediaSchema),
    defaultValues: {
      profileImgUrl: currentProfileImgUrl,
      bio: currentBio,
      followers: currentFollowers.toString(),
      following: currentFollowing.toString(),
      postsCount: currentPostsCount.toString(),
      profileLink: currentProfileLink,
      isPrivate: currentIsPrivate,
    },
    mode: 'onChange',
  })

  const { handleSubmit, control } = form

  const onSubmit = (data: any) => {
    handleUpdateSocialMediaInfo(data)
  }

  return (
    <Dialog>
      <DialogTrigger>
        <Button
          className="w-10 h-10 bg-gray-200 rounded-[100px] justify-center items-center gap-1 mr-2"
          size={'icon'}
        >
          <Edit
            size="24"
            color={weCheckPalette.icon.disableIcon}
            variant="Outline"
          />
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[60%] h-full overflow-y-scroll">
        <DialogHeader>
          <DialogTitle>Modify Social Media Data</DialogTitle>
        </DialogHeader>
        <div>
          <Form {...form}>
            <form name="SocialMediaForm">
              <div className="p-2">
                <div className="flex flex-col gap-6">
                  <div className=" flex flex-col gap-3">
                    <FormField
                      control={control}
                      name="bio"
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormLabel>Bio</FormLabel>
                          <FormControl>
                            <Textarea {...field} placeholder="Bio" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={control}
                      name="profileImgUrl"
                      render={({ field }) => (
                        <FormItem className="w-full ">
                          <FormLabel>Profile Img Url</FormLabel>
                          <FormControl>
                            <Textarea
                              {...field}
                              placeholder="Profile Img Url"
                            />
                          </FormControl>
                          <FormMessage data-name="profileImgUrl" />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={control}
                      name="profileLink"
                      render={({ field }) => (
                        <FormItem className="w-full mr-6">
                          <FormLabel>Profile Link</FormLabel>
                          <FormControl>
                            <Textarea {...field} placeholder="Profile Link" />
                          </FormControl>
                          <FormMessage data-name="profileLink" />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={control}
                      name="followers"
                      render={({ field }) => (
                        <FormItem className="w-full mr-6">
                          <FormLabel>Followers</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              placeholder="followers"
                              type="number"
                            />
                          </FormControl>
                          <FormMessage data-name="Followers" />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={control}
                      name="following"
                      render={({ field }) => (
                        <FormItem className="w-full mr-6">
                          <FormLabel>Following</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              placeholder="Following"
                              type="number"
                            />
                          </FormControl>
                          <FormMessage data-name="following" />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={control}
                      name="postsCount"
                      render={({ field }) => (
                        <FormItem className="w-full mr-6">
                          <FormLabel>Posts Count</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              placeholder="Posts Count"
                              type="number"
                            />
                          </FormControl>
                          <FormMessage data-name="postsCount" />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="isPrivate"
                      render={({ field }) => (
                        <FormItem className="w-full mr-6 flex flex-col">
                          <FormLabel>Is Private?*</FormLabel>
                          <div className="flex gap-3">
                            <FormControl>
                              <Switch
                                className="w-11 h-6"
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                            <p className="text-neutral-800 text-medium font-medium">
                              {field.value ? (
                                <p className="text-green-600">Yes</p>
                              ) : (
                                <p className="text-red-600">No</p>
                              )}
                            </p>
                          </div>
                          <FormMessage data-name="isPrivate" />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="flex gap-6">
                    <Button
                      onClick={handleSubmit(onSubmit)}
                      form="SocialMediaForm"
                      isLoading={isLoading}
                      type="submit"
                      disabled={!form.formState.isValid}
                      className="h-[46px] px-5 py-3 bg-neutral-800 rounded-lg"
                    >
                      <p className="text-neutral-50 text-base font-medium tracking-wide">
                        Update
                      </p>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
